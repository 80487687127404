export { default as Background } from './pochingo-bg-2.jpg';
export { default as BgCoinContainer } from './bgCoinContainer2.png';
export { default as BgHolesContainer } from './holes.png';
export { default as BgHole } from './bgHole.png';
export { default as Pane } from './paneContainer.png';
export { default as BtnRoll } from './btn.png';
export { default as NumBlue } from './numBlue.png';
export { default as NumGreen } from './numGreen.png';
export { default as NumOrange } from './numOrange.png';
export { default as NumWhite } from './numWhite.png';
export { default as Num7 } from './num7.png';
export { default as JackPot } from './jackpot.png';
export { default as Menu } from './MENU.png';
export { default as Menu1 } from './MENU1.png';
export { default as BtnMax } from './btnMax.png';
export { default as BtnMin } from './btnMin.png';
export { default as Handler } from './handler.png';
export { default as IconCheckNum } from './icon-checknum.png';
export { default as StarStreak } from './StarStreak.png';
export { default as NewDice1 } from './dices/1.png';
export { default as NewDice2 } from './dices/2.png';
export { default as NewDice3 } from './dices/3.png';
export { default as NewDice4 } from './dices/4.png';
export { default as NewDice5 } from './dices/5.png';
export { default as NewDice6 } from './dices/6.png';
export { default as SoundOn } from './sound-on.png';
export { default as SoundOff } from './sound-off.png';
export { default as ImgTile } from './ImgTile.png';
export { default as GoldCoin } from './coin.png';
export { default as Info } from './info@2x.png';
export { default as LastChance } from './last-chance@2x.png';
