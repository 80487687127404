import React, { useContext, useState } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import { MySwitch } from "components/MySwitch";
import SingleAntImage from "components/SingleAntImage";
import { SoundOff, SoundOn } from "assets/images";
import { useThemeStyles } from "service/theme";

const OptionsHud = () => {
  const {
    autoDiceRef,
    isAuto,
    isPlayingMusic,
    isPlayingSound,
    isInstruction,
    setIsAuto,
    setIsInstruction,
    setInstructionStage,
    setIsPlayingSound,
    setIsPlayingMusic,
    timeoutRef,
  } = useContext(DataContext)
  const [styleModule, setStyleModule] = useState();

  const onSwitchIntruction = (e) => {
    setIsInstruction(!isInstruction);
    setInstructionStage(1);
  };

  const onClickBtnAuto = (checked) => {
    if (!checked) clearTimeout(timeoutRef.current);
    setIsAuto(checked);
  };

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'components/OptionsHud');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------
	return (
      <styleModule.Wrapper>
        {isPlayingMusic ? (
          <SingleAntImage
            src={SoundOn}
            className="cursor"
            width={55}
            onClick={() => setIsPlayingMusic(false)}
          />
        ) : (
          <SingleAntImage
            src={SoundOff}
            className="cursor"
            width={55}
            onClick={() => setIsPlayingMusic(true)}
          />
        )}
        <div className="toggle-wrapper-md">
          <h5>Instructions</h5>
          <MySwitch checked={isInstruction} onChange={onSwitchIntruction} />
        </div>
        <div className="toggle-wrapper-sm">
          <MySwitch
            checked={isInstruction}
            checkedChildren="Instruction"
            unCheckedChildren="Instruction"
            onChange={onSwitchIntruction}
          />
        </div>
        <div className="toggle-wrapper-md">
          <h5>Auto Pick</h5>
          <div ref={autoDiceRef}>
            <MySwitch id="autopick" checked={isAuto} onChange={onClickBtnAuto} />
          </div>
        </div>
        <div className="toggle-wrapper-sm">
          <div ref={autoDiceRef}>
            <MySwitch
              checked={isAuto}
              id="autopick"
              checkedChildren="Auto Pick"
              unCheckedChildren="Auto Pick"
              onChange={onClickBtnAuto}
            />
          </div>
        </div>
        {isPlayingSound ? (
          <i
            className="fas fa-volume-up icon-music"
            onClick={() => setIsPlayingSound(false)}
          />
        ) : (
          <i
          className="fas fa-volume-mute icon-music"
            onClick={() => setIsPlayingSound(true)}
          />
        )}
      </styleModule.Wrapper>
	)
}

export default OptionsHud;