import React from 'react';
import Theme from 'containers/Theme';

const BinaryToggle = (props) => {
	const { id, checked, onChange, styleModule } = props;

	return (
		<styleModule.Wrapper
			id={id}
			onClick={onChange}
			checked={checked}
		/>
	)
}

export default Theme(BinaryToggle, 'components/BinaryToggle');