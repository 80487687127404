import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useThemeStyles } from "service/theme";

export default function Box({
  children, 
  className, 
  framer, 
  onClick = () => {}, 
  ...otherProps
}) {
  const [styleModule, setStyleModule] = useState();

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'components/Box');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------

  return (
    <styleModule.BoxContainer 
      className={className} 
      {...framer} 
      style={{...otherProps}}
      onClick={onClick}
    >
      {children}
    </styleModule.BoxContainer>
  )
}

Box.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  framer: PropTypes.object,
  onClick: PropTypes.func,
};
