import React, { useState, useRef, useEffect, useContext } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import customAxios from 'service/customAxios';
import { socket } from 'service/socket';
import Theme from 'containers/Theme';
import { DataContext } from 'contexts/DataContextContainer';
import { motion, AnimatePresence } from 'framer-motion';

function NextModal(props){
  const {styleModule} = props;
  const history = useHistory();
  const [count, setCount] = useState(30);
  const [registered, setRegistered] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const {
    setStartedRound,
    startedRound,
    setEndRound,
    maxRounds,
    authUser
  } = useContext(DataContext);
  const timer = useRef(null);
  const tourId = useRef(null);
  const creatorId = useRef(null);

  useEffect(() => {
    socket.on("TOURNAMENT_CREATE", (data) => {
      tourId.current = data.id;
    });

    socket.on("TOURNAMENT_ROUND_START", (data) => {
      setStartedRound(true);
    });

    timer.current = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);
    
    return () => {
      clearInterval(timer.current);
      socket.off("TOURNAMENT_CREATE");
      socket.off("TOURNAMENT_ROUND_START");
    };
  }, []);

  useEffect(() => {
    if(count < 1){
      setStartedRound(false);
      setEndRound(false);
      if(registered){
        history.push(`/tournaments/${tourId.current}/1`);
      }else{
        handleLobby();
      }
    }else if(count < 6){
      showRegistration && setShowRegistration(false);
      if(!startedRound && creatorId.current === authUser.id)
        startRound();
    }
  });

  const makeId = (n) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < n; i++ ) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const createGame = async () => {
    const { ok, data } = await customAxios.post("api/v1/tournament", {
      name: makeId(5),
      end_round: maxRounds,
    });
    if(ok) {
      tourId.current = data.id;
      creatorId.current = authUser.id;
    }
  }

  const removePlayer = async () => {
   await customAxios.delete(`api/v1/tournament/${tourId.current}/player`);
  };

  const startRound = async () => {
    await customAxios.post(`api/v1/tournament/${tourId.current}/round/1`);
  };

  const handleLobby = () => {
    if(registered){
      removePlayer();
    }
    history.replace('/tournaments/join');
  };

  const handleRegister = async () => {
    if(registered){
      removePlayer();
      setRegistered(false);
    }else{
      setShowRegistration(true);
    }
  };

  const handleConfirm = async () => {
    setRegistering(true);
    if(!tourId.current){
      createGame();
    }else{
      const { ok } = await customAxios.post(`api/v1/tournament/${tourId.current}/player`);
      if(ok)
        await customAxios.patch(`api/v1/tournament/${tourId.current}/player`);
    }
    setRegistering(false);
    setRegistered(true);
    setShowRegistration(false);
  };

  const handleCancel = () => {
    if(!registering) setShowRegistration(false);
  };

  return(
    <styleModule.StyleContainer>
      <AnimatePresence>
        <motion.div
          key={0}
          layout
          initial="hidden"
          animate="visible"
          variants={{
            visible: { scale: 1, transition: {type: 'spring', bounce: 0.5} },
            hidden: { scale: 0, transition: {type: 'tween', duration: 0.25} }
          }}
          className="modal-box"
        >
          <h1>Dollar Micro</h1>
          <div className="jackpot-box">
            <h2>JACKPOTS!</h2>
            <hr/>
            <div className="prize">$100,000.00</div>
            <h3>MAJOR</h3>
            <hr/>
            <div className="prize">$1,234.56</div>
            <h3>MINOR</h3>
          </div>
          <div className="info-box clear">
            <table>
              <tbody>
                <tr>
                  <td>Buy-In:</td>
                  <td><span className="yellow"><b>$0.85 + $0.05 + $0.10</b></span></td>
                </tr>
                <tr>
                  <td>Players:</td>
                  <td><b>1,234</b></td>
                </tr>
                <tr>
                  <td>Prize Pool:</td>
                  <td><b>$1,048.90</b></td>
                </tr>
              </tbody>
            </table>
            <div className="counter-box">
              <h4>Next Game</h4>
              <div className="counter">{count}</div>
            </div>
          </div>
          {(count > 5 && creatorId.current !== authUser.id) && (
            <motion.div
              key={1}
              layout
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { scale: 1, transition: {type: 'spring', bounce: 0.5, delay: 0.75} },
                hidden: { scale: 0, transition: {type: 'tween', duration: 0.25} }
              }}
              className="btn"
            >
              <Button className="btn__green" onClick={handleRegister} loading={registering}>{registered ? 'UNREGISTER' : 'REGISTER'}</Button>
            </motion.div>
          )}
          {(count > 5 && creatorId.current !== authUser.id) && (
            <motion.div
              key={2}
              layout
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { scale: 1, transition: {type: 'spring', bounce: 0.5, delay: 1.5} },
                hidden: { scale: 0, transition: {type: 'tween', duration: 0.25} }
              }}
              className="btn"
            >
              <Button className="btn__purple" onClick={handleLobby}>LOBBY</Button>
            </motion.div>
          )}
        </motion.div>
        {showRegistration && (
          <motion.div
            key={3}
            layout
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: { scale: 1, transition: {type: 'spring', bounce: 0.5} },
              hidden: { scale: 0, transition: {type: 'tween', duration: 0.25} }
            }}
            className="tournament-info"
          >
            <h5>Tournament Registration</h5>
            <div className="info-box clear">
              <div>Dollar Micro</div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Buy-In:</td>
                      <td><b>$0.85 + $0.05 + $0.10</b></td>
                    </tr>
                    <tr>
                      <td>Players:</td>
                      <td><b>1,234</b></td>
                    </tr>
                    <tr>
                      <td>Prize Pool:</td>
                      <td><b>$1,048.90</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className="balance">
              <tbody>
                <tr>
                  <td><b>Available Balance:</b></td>
                  <td>$10.25</td>
                </tr>
                <tr>
                  <td><b>Your account will be charged:</b></td>
                  <td>$1</td>
                </tr>
                <tr>
                  <td><b>Game Rules</b></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="btn">
              <Button className="btn__green" onClick={handleConfirm} loading={registering}>CONFIRM</Button>
              <Button className="btn__box" onClick={handleCancel}>CANCEL</Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </styleModule.StyleContainer>
  );
  
}
export default Theme(NextModal, 'components/NextModal');