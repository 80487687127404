import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getJwt, clearJwt } from './jwt';

//change to an environment url eventually
const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/',
});

customAxios.defaults.headers.common['Content-Type'] = 'application/json'
customAxios.defaults.headers.post['Content-Type'] = 'application/json'
customAxios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const setAuthHeader = (token) => {
  customAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
  
if (getJwt()) {
  setAuthHeader(getJwt());
}

customAxios.interceptors.response.use(response => {
  if (response.config) console.info(`[AXIOS]:${response.config.method.toUpperCase()}:${response.config.url}`, {config: response.config, data: response.data});
  return { ok: true, status: response.status, data: (response || {}).data }
}, error => {
  console.log({error})
  if (error.response.data.message === 'Please login again') {
    clearJwt();
    let history = useHistory();
    history.push('/');
  }
  
  if (error.response) {
    return {
      ok: false,
      status: error.response.data.status,
      data: { data: { success: false } },
      errMessage: error.response.data.message,
    }
  } else {
    return {
      ok: false,
      status: error.message,
      data: { data: { success: false } },
      errMessage: error.message,
    }
  }
});

export { setAuthHeader };

customAxios.origin = axios;
export default customAxios;