import { CountdownCircleTimer } from "react-countdown-circle-timer";
import React, { useState } from 'react'
import { useThemeStyles } from "service/theme";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 80,
  strokeWidth: 6,
  trailColor: '#000'
};

const renderTime = (time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time < 10 ? `0${time}` : time}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;

export default function Countdown({
  onCompleteCountDown = () => {},
}) {
  const [styleModule, setStyleModule] = useState();

  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = stratTime + 1 * 1 * 60 - 55; // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'containers/MultiPlayer/Countdown');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------
  return (
    <styleModule.CountdownContainer>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#E1B347"]]}
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => [
          remainingTime - totalElapsedTime > hourSeconds
        ]}
      >
        {({ elapsedTime }) =>
          renderTime(getTimeHours(daySeconds - elapsedTime))
        }
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#519133"]]}
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => [
          remainingTime - totalElapsedTime > minuteSeconds
        ]}
      >
        {({ elapsedTime }) =>
          renderTime(getTimeMinutes(hourSeconds - elapsedTime))
        }
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#3079B0"]]}
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => {
          onCompleteCountDown(!(remainingTime - totalElapsedTime > 0))
          return [
            remainingTime - totalElapsedTime > 0
          ]
        }}
      >
        {({ elapsedTime }) =>
          renderTime(getTimeSeconds(elapsedTime))
        }
      </CountdownCircleTimer>
    </styleModule.CountdownContainer>
  );
}

