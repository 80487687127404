import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'clone-deep';
import { numsTemp } from 'service';
import useLocalStorage from 'react-use-localstorage';
const DataContext = React.createContext(null);

export { DataContext };

export const initialBalance = 1000;

const DataContextContainer = (props) => {

  const autoDiceRef = useRef(null);
  const autoBaseballRef = useRef(null);
  const timeoutRef = useRef(null);
  const [authUser, setAuthUserContext] = useLocalStorage('pochingo-user', '{}');
  const [balance, setBalance] = useState(100);
  const [bet, setBet] = useState(0.5);
  const [bonus, setBonus] = useState(0);
  const [roundBonus, setRoundBonus] = useState(bet);
  const [earn, setEarn] = useState(null);
  const [jackpot, setJackpot] = useState(100);
  const [started, setStarted] = useState(false);
  const [endRound, setEndRound] = useState(false);
  const [winnedLines, setWinnedLines] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [startGame, setStartGame] = useState(false);
  const [rolledNum, setRolledNum] = useState();
  const [numbers, setNumbers] = useState(cloneDeep(numsTemp));
  const [statuses, setStatuses] = useState(Array.from({ length: 25 }, () => 0));

  const [curRollIndex, setCurRollIndex] = useState(1);
  const [curIndex, setCurIndex] = useState(1);
  const [holeTimes, setHoleTimes] = useState(0);
  const [bonuses, setBonuses] = useState(Array.from({ length: 15 }, () => null));
  const [streakBonuses, setStreakBonuses] = useState(Array.from({ length: 25 }, () => null));
  const [activeBtn, setActiveBtn] = useState(true);
  const [skipping, setSkipping] = useState(false);
  const [restarted, setRestarted] = useState(false);
  const [newWinGroupKeys, setNewWinGroupKeys] = useState([]);

  const [countEnd, setCountEnd] = useState(false);
  const [filled, setFilled] = useState(false);

  const [isDev, setIsDev] = useState(false);
  const [pochingoPay, setPochingoPay] = useState(100000000);
  const [isInstruction, setIsInstruction] = useState(false);
  const [showStreakStars, setShowStreakStars] = useState(false);
  const [showPochingoStars, setShowPochingoStars] = useState(false);
  const [show100Points, setShow100Points] = useState(false);
  
  const [showPayTable, setShowPayTable] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [instructionStage, setInstructionStage] = useState(1);
  const [startedRound, setStartedRound] = useState(false);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [additionalScore, setAdditionalScore] = useState({});
  const [moneyRank, setMoneyRank] = useState(3);
  
  const [maxRounds, setMaxRounds] = useState(1000);
  const [sessionId] = useState(uuidv4());
  const [endGame, setEndGame] = useState(false);
  const [completedRound, setCompletedRound] = useState(false);
  const [streakStrikes, setStreakStrikes] = useState(0);

  const [isAuto, setIsAuto] = useState(false);
  const [isPlayingMusic, setIsPlayingMusic] = useState(true);
  const [isPlayingSound, setIsPlayingSound] = useState(true);

  const setAuthUser = (req) => {
    setAuthUserContext(JSON.stringify(req));
  } 

  return(
    <DataContext.Provider
      value={{ 
        autoDiceRef,
        autoBaseballRef,
        timeoutRef,
        authUser: JSON.parse(authUser), setAuthUser,
        balance: parseFloat(balance), setBalance,
        bet, setBet,
        bonus, setBonus,
        pochingoPay, setPochingoPay,
        roundBonus, setRoundBonus,
        rolledNum, setRolledNum,
        earn, setEarn, 
        jackpot, setJackpot,
        started, setStarted,
        startGame, setStartGame,
        endRound, setEndRound,
        startedRound, setStartedRound,
        winnedLines, setWinnedLines,
        showModal, setShowModal,
        statuses, setStatuses,
        numbers, setNumbers,
        curRollIndex, setCurRollIndex,
        curIndex, setCurIndex,
        holeTimes, setHoleTimes,
        bonuses, setBonuses,
        streakBonuses, setStreakBonuses,
        activeBtn, setActiveBtn,
        skipping, setSkipping,
        restarted, setRestarted,
        newWinGroupKeys, setNewWinGroupKeys,
        countEnd, setCountEnd,
        filled, setFilled,
        isDev, setIsDev,
        isInstruction, setIsInstruction,
        showStreakStars, setShowStreakStars,
        showPochingoStars, setShowPochingoStars,
        showPayTable, setShowPayTable,
        showGuide, setShowGuide,
        sessionId,
        instructionStage, setInstructionStage,
        maxRounds, setMaxRounds,
        teamPlayers, setTeamPlayers,
        additionalScore, setAdditionalScore,
        endGame, setEndGame,
        completedRound, setCompletedRound,
        isPlayingMusic, setIsPlayingMusic,
        isPlayingSound, setIsPlayingSound,
        isAuto, setIsAuto,
        streakStrikes, setStreakStrikes,
        show100Points, setShow100Points,
        moneyRank, setMoneyRank 
      }}
    >
      { props.children }
    </DataContext.Provider>
  )
}

DataContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


export default DataContextContainer;
