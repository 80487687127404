import React, { useState } from 'react'
import { useThemeStyles } from "service/theme";

export const SingleButton = ({
  bg,
  animate = false,
  className = '',
  width = '200px',
  height = '80px',
  renderTitle = () => { },
  onPress = () => { },
}) => {
  const [styleModule, setStyleModule] = useState();

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'components/SingleButton');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------

  return (
    <styleModule.SingleButtonContainer
      className={className}
      height={height}
      bg={bg}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{width: width}}
      animate={animate}
      onClick={onPress}
    >
      {renderTitle()}
    </styleModule.SingleButtonContainer>);
};