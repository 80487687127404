import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from 'contexts/DataContextContainer';
import { scoreOptions } from 'service';
import ApngComponent from 'react-apng';
import Theme from 'containers/Theme';
import query from 'query-string';
import { useHistory } from 'react-router-dom';
import {
  RedBallFly,
  WhiteBallFly,
  ParticleHit,
  RedWhiteBallFly
} from 'assets/images/battingpractice';

const BallThrow = (props) => {
  const { number, styleModule, throwBall, themeSounds, watchMode, endRound, streakStrikes, skipping } = props;
  const { isPlayingSound, additionalScore } = useContext(DataContext);
  const history = useHistory();
  const { isLast } = query.parse(history.location.search);
  const [showNumber, setShowNumber] = useState(false);
  const [isThrow, setIsThrow] = useState(true);
  const isIntro = useRef(true);
  const previousStreakStrike = useRef(0);
  const isHitPlaying = useRef(true);
  const ball = useRef();
  const redBall = useRef();
  const whiteBall = useRef();
  const redWhiteBall = useRef();
  const hit = useRef();
  const canPlaySound = useRef(isPlayingSound && !watchMode);

  const [ playSoundBatImpact ] = themeSounds.useSoundBatImpact();
  const [ playSoundBallSwoosh ] = themeSounds.useSoundBallSwoosh();
  const [ playSoundSpinStop ] = themeSounds.useSoundSpinStop();
  const [ playSoundSpinning, { stop: stopSoundSpinning } ] = themeSounds.useSoundSpinning();
  const [ playSoundSuspense ] = themeSounds.useSoundSuspense();

  let classBall = 'ball';
  let classHit = 'hit-impact';
  let classNumber = 'number';

  useEffect(() => {
    if (isThrow && endRound) {
      let delay = 2000;

      if (streakStrikes >= 3) {
        delay = 8000;
      }

      const timer = setTimeout(() => {
        setIsThrow(false);
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [isThrow, endRound, streakStrikes]);

  useEffect(() => {
    let delay = 0;
    if(!throwBall){
      delay = 500;
    }
    let t = setTimeout(() => {
      setIsThrow(throwBall);
    }, delay);
    return () => clearTimeout(t);
  }, [throwBall]);

  useEffect(() => {
    canPlaySound.current = isPlayingSound && !watchMode;
  }, [isPlayingSound, watchMode]);

  useEffect(() => {
    if(watchMode){
      isIntro.current = false;
    }
  }, [watchMode]);

  useEffect(() => {
    const frameHandler = (frame) => {
      if(ball.current && frame >= ball.current.apng.frames.length - 1){
        ball.current.player.off('frame', frameHandler);
        if(canPlaySound.current) {
          if(canRenderRedWhiteBall()){
            stopSoundSpinning();
            playSoundSpinStop();
          }
        }
        setShowNumber(true);
        if (streakStrikes === 0 && previousStreakStrike.current === 2) {
          previousStreakStrike.current = 0;
        }else{
          previousStreakStrike.current = streakStrikes;
        }
      }
    }

    if (isThrow && !isIntro.current) {
      isHitPlaying.current = false;
      if (canRenderRedWhiteBall()) {
        ball.current = redWhiteBall.current;
        setTimeout(() => {
          if(canPlaySound.current){
            playSoundSpinning();
            playSoundSuspense();
          }
        }, 150);
      } else {
        ball.current = number === 7 ? redBall.current : whiteBall.current;
      }
      if(ball.current?.player) ball.current.player.on('frame', frameHandler);
      ball.current.one();
    } else if(!isThrow) {
      if(ball?.current){
        ball.current.stop();
        if(ball?.current?.player)
          ball.current.player.off('frame', frameHandler);
      }
      isIntro.current = false;
      setShowNumber(false);
    }
  }, [isThrow, number, playSoundSpinning, playSoundSuspense, playSoundSpinStop, stopSoundSpinning, streakStrikes]);

  const canRenderRedWhiteBall = () => previousStreakStrike.current === 2;

  const getBallDisplayProperty = (type) => {
    if(showNumber){
      return 'none';
    }else if (canRenderRedWhiteBall()) {
      if (type === 'red_white') {
        return 'block';
      } else {
        return 'none';
      }
    }else if (type === 'red' && number === 7) {
      return 'block';
    }else if (type === 'white' && number !== 7) {
      return 'block';
    }else{
      return 'none';
    }
  }

  const playBallSwoosh = (delay) => {
    setTimeout(() => {
      if(canPlaySound.current){
        playSoundBallSwoosh();
      }
    }, delay || 0);
  }

  const showRedBall = () => {
    classBall += ' ball--down ball--red ball--hide';
    if (!isHitPlaying.current) {
      playBallSwoosh(1500);
    }
    isHitPlaying.current = true;
    if (canRenderRedWhiteBall()) {
      classNumber = `${classNumber} show-red-ball`;
    }
  }

  const showWhiteBall = () => {
    classBall += ' ball--white';
    classNumber += ' show-number';
    if (canRenderRedWhiteBall()) {
      classNumber = `${classNumber} adjust-red-white-ball`;
    }
    if (additionalScore || watchMode) {
      if (additionalScore?.addScore === scoreOptions.checkMark || skipping) {
        classBall += ' ball--foul ball--hide';
        if (!isHitPlaying.current) {
          setTimeout(() => {
            if(canPlaySound.current){
              playBallSwoosh();
            }
          }, 1500);
        }
      } else {
        classBall += ' ball--hit';
        if(watchMode) {
          classBall += ' ball--hide';
        }else{
          classHit += ' hit-impact--show';
        }
        if (!isHitPlaying.current) {
          hit.current.one();
          setTimeout(() => {
            if(canPlaySound.current){
              playSoundBatImpact();
              playBallSwoosh();
            }
          }, 150);
        }
      }
      isHitPlaying.current = true;
    }
  }
  
  if (isIntro.current || !isThrow){
    classBall += ' ball--hide';
  } else if(showNumber){
    if (number === 7) {
      showRedBall();
    } else {
      showWhiteBall();
    }
  }
  
  return(
    <styleModule.StyleContainer number={number} watchMode={watchMode} style={{ visibility: isThrow && !isIntro.current && isLast !== 'y' ? 'visible' : 'hidden' }}>
      <div className={classBall}>
        <ApngComponent ref={redBall} className='ball_canvas' style={{ display: getBallDisplayProperty('red')}} src={RedBallFly} rate={1} />
        <ApngComponent ref={whiteBall} className='ball_canvas' style={{ display: getBallDisplayProperty('white')}} src={WhiteBallFly} rate={1} />
        <ApngComponent ref={redWhiteBall} className='ball_canvas' style={{ display: getBallDisplayProperty('red_white')}} src={RedWhiteBallFly} rate={0.7} />
        <div className={classNumber} />
      </div>
      <ApngComponent ref={hit} className={classHit} src={ParticleHit} rate={1} />
    </styleModule.StyleContainer>
  );
}

export default Theme(BallThrow, 'components/BallThrow');