import React, { useContext, useEffect, useState } from "react";
import { ThemeContext, Themes } from 'contexts/ThemeContext';
import {
  OutsCounterContainer,
  CountedRed,
  OutsCountedContainer,
  OutsLabel,
  OutsEmptySlots
} from 'components/StreakCounter/theme/battingpractice/style'

const Counter = (props) => {
  const { count, label } = props;
  const theme = useContext(ThemeContext);
  const [internalCount, setIneternalCount] = useState(0);

  useEffect(() => {
    setIneternalCount(count);
  }, [count]);

  // This component should only rendered for baseball bingo
  if (theme !== Themes.BATTINGPRACTICE) {
    return null;
  }

  return (
    <OutsCounterContainer>
      <OutsLabel>{label}</OutsLabel>
      <OutsEmptySlots />
      <OutsCountedContainer>
        {Array(internalCount).fill(null).map(() => (<CountedRed />))}
      </OutsCountedContainer>
    </OutsCounterContainer>
  )
};

export default Counter;