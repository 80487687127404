import React, { useContext, useEffect, useState, useRef } from "react";
import { ThemeContext, Themes } from 'contexts/ThemeContext';
import Counter from './Counter';
import Outs from './Outs';
import Theme from 'containers/Theme';

const StreakCounter = (props) => {
  const { hitStreak, foulBall, outs, styleModule } = props;
  const theme = useContext(ThemeContext);
  const [curHitStreak, setCurHitStreak] = useState(0);
  const [curFoulBall, setCurFoulBall] = useState(0);
  const [curOuts, setCurOuts] = useState(0);
  let streakOut = useRef(0);

  useEffect(() => {
    const delay = streakOut.current >= 2 ? 4000 : 1000;
    const t = setTimeout(() => {
      if(hitStreak !== curHitStreak){
        setCurHitStreak(hitStreak);
        streakOut.current = 0;
      }
      if(foulBall !== curFoulBall){
        setCurFoulBall(foulBall);
        streakOut.current = 0;
      }
      if(outs !== curOuts){
        setCurOuts(outs);
        streakOut.current++;
      }
    }, delay);
    return() => {
      clearTimeout(t);
    };
  }, [curHitStreak, curFoulBall, curOuts, hitStreak, foulBall, outs]);

  // This component should only rendered for baseball bingo
  if (theme !== Themes.BATTINGPRACTICE) {
    return null;
  }

  return (
    <styleModule.ComponentContainer>
      <styleModule.TopDown>
        <Counter label="HIT STREAK" count={curHitStreak} />
        <Counter type="blue" label="FOUL BALL" count={curFoulBall} />
      </styleModule.TopDown>
      <Outs label="OUTS" count={curOuts} />
    </styleModule.ComponentContainer>
  )
};

export default Theme(StreakCounter, 'components/StreakCounter');