import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useThemeStyles } from "service/theme";

export default function SingleAntImage(props) {
  const { 
    className = '',
    children,
    src,
    height = '100%',
    width = 'auto',
    style,
    borderradius = '0px',
    preview = false,
    onClick = () => {}
  } = props;
  const [styleModule, setStyleModule] = useState();

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'components/SingleAntImage');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------
  return (
    <styleModule.Wrapper className={`${className}-wrapper`}>
      <styleModule.SingleAntImageContainer
        className={className}
        width={width}
        height={height}
        borderradius={borderradius}
        src={src}
        style={style}
        preview={preview}
        onClick={onClick}
        // placeholder={
        //   <Image
        //     src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
        //     width={width}
        //   />
        // }
      />
      {children}
    </styleModule.Wrapper>
  )
}

SingleAntImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  style: PropTypes.object,
  borderradius: PropTypes.string,
  preview: PropTypes.bool,
  onClick: PropTypes.func,
};
