/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import SingleText from "components/SingleText";
import { statusColors } from "service";
import SingleAntImage from "components/SingleAntImage";
import { IconCheckNum, StarStreak } from "assets/images";
import Theme from 'containers/Theme';
import { ThemeContext, Themes } from "contexts/ThemeContext";

const MultiNumber = ({ order, status, isFreePick, rolledClick, skipping, styleModule, globalThemeData }) => {
  const theme = useContext(ThemeContext);
  const [curStatus, setCurStatus] = useState(statusColors.white);

  useEffect(() => {
    if(curStatus === status) return;
    let delay = 0;
    if(status == statusColors.orange && theme !== Themes.DICE){
      delay = 1000;
    }
    const t = setTimeout(() => {
      if(curStatus !== status){
        setCurStatus(status);
      }
    }, delay);
    return () => clearTimeout(t);
  }, [status]);

  return (
    <styleModule.MultiNumberContainer className={`number ${order === 13 && "number7"}`} status={parseInt(curStatus)}>
      <SingleText size={40} weight={700} color={`${curStatus == 0 && order != 13 ? "#242e42" : "white"}`}>
        {globalThemeData.numbersTemp[order - 1]}
      </SingleText>
      {rolledClick === globalThemeData.numbersTemp[order - 1] && skipping && (
        <SingleAntImage className="icon-check-num" src={IconCheckNum} width="100%" height="auto" />
      )}
      {isFreePick && curStatus == statusColors.white && (
        <SingleAntImage className="icon-free-num" src={StarStreak} width="100%" height="auto" />
      )}
    </styleModule.MultiNumberContainer>
  );
};

export default React.memo(Theme(MultiNumber, 'components/MultiNumber'));
