import { NumBlue, NumGreen, NumOrange, NumWhite } from "assets/images";
import { keyframes } from "@emotion/core";

export const customAnimation = keyframes`
  0% { transform: scale( 1 ); }
  25% { transform: scale( 1.25 ); }
  50% { transform: scale( 1 ); }
  75% { transform: scale( 1.25 ); }
  100% { transform: scale( 1 ); }
`;
export const roundsCountainerFlash = keyframes`
  0% { transform: scaleX( 1 ); }
  25% { transform: scaleX( 1.1 ); }
  50% { transform: scaleX( 1 ); }
  75% { transform: scaleX( 1.1 ); }
  100% { transform: scaleX( 1 ); }
`;

const mainWidth = 402;
const holesWidth = 200;
const holesHeight = holesWidth / 5;
const betWidth = mainWidth / 3;
const betHeight = betWidth * 0.2;
const gutter = 4;
const numberWidth = (mainWidth - 31 - gutter * 4) / 5;
const coinContainerWidth = 200;
const coinContainerHeight = coinContainerWidth * 0.33;
const roundWidhtHeight = mainWidth / 9.5 - 20;
const gapBetweenRound = (mainWidth - 35 - roundWidhtHeight * 15) / 14;
const stepWidthRound = 14 + gapBetweenRound;

const multiPaneWidth = 196;
const multiNumWidth = 34;
const multiNumGutter = (196 - 14 - multiNumWidth * 5) / 4;

export const sizes = {
  mainWidth: mainWidth,
  mainWidthM: 350,
  betWidth: betWidth,
  bonusWidth: betWidth,
  betHeight: betHeight,
  bonusHeight: betHeight,
  headerTextWidth: mainWidth / 2,
  coinContainerWidth: coinContainerWidth,
  coinContainerHeight: coinContainerHeight,
  roundsContainerWidth: mainWidth,
  roundsContainerHeight: mainWidth / 9,
  gapBetweenRound: gapBetweenRound,
  roundWidhtHeight: roundWidhtHeight,
  holesWidth: holesWidth,
  holesHeight: holesHeight,
  holeWidth: holesHeight - 15,
  numberWidth: numberWidth,
  gutter: gutter,
  stepWidthRound: stepWidthRound,
  streakBonusWidth: (mainWidth * 2) / 3,

  multiPaneWidth,
  multiNumWidth,
  multiNumGutter,
};

export const numbersTemp = [
  2,
  9,
  8,
  6,
  3,
  6,
  4,
  12,
  10,
  8,
  11,
  5,
  7,
  2,
  5,
  5,
  12,
  10,
  8,
  9,
  8,
  6,
  3,
  4,
  11,
];

export const numberInfo = {
  1: {
    background: NumGreen,
    color: "white",
  },
  2: {
    background: NumBlue,
    color: "white",
  },
  3: {
    background: NumOrange,
    color: "white",
  },
  0: {
    background: NumWhite,
    color: "#242e42",
  },
};

export const dimentions = {
  totalLeft: 160,
  totalTop: 70,
  additionalLeft: 132,
  additionalTop: 373,
};
