import styled from 'styled-components';
import {
  StreakContainer,
  StreakSlots,
  StreakGreen,
  StreakBlue,
  OutsIndicator,
  OutsContainer,
  OutsSlots
} from "assets/images/battingpractice";

export const ComponentContainer = styled.div`
  display: flex;
`;

export const TopDown = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StreakCounterContainer = styled.div`
  display: flex;
  position: relative;
  background-image: url(${StreakContainer});
  background-repeat: no-repeat;
  background-size: cover;
  width: 267px;
  height: 36px;
  align-items: center;
`;

export const OutsCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url(${OutsContainer});
  background-repeat: no-repeat;
  background-size: cover;
  width: 70px;
  height: 74px;
  align-items: center;
  margin-left: 4px;
`;

export const Label = styled.div`
  text-shadow: 0px 1px 0 rgba(255, 255, 255, 0.35);
  font-family: "Barlow Semi Condensed Bold";
  font-size: 15.5px;
  font-weight: bold;
  font-stretch: semi-condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.78px;
  text-align: left;
  color: #03060f;
  width: 105px;
  line-height: 32px;
  text-align: center;
  user-select: none;
`;

export const OutsLabel = styled(Label)`
  width: unset;
`;

export const EmptySlots = styled.div`
  display: block;
  background-image: url(${StreakSlots});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 150px;
  height: 20px;
`;

export const OutsEmptySlots = styled.div`
  display: block;
  background-image: url(${OutsSlots});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 45px;
  height: 14px;
  margin-top: 15px;
`;

export const CountedContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  margin-left: 105px;
  transform: translateY(50%);
`;

export const OutsCountedContainer = styled.div`
  display: flex;
  position: absolute;
  width: 45px;
  height: 14px;
  top: 0;
  margin-top: 44px;
`;

export const Counted = styled.div`
  display: block;
  background-image: url(${StreakGreen});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  margin-top: 3px;
`;

export const CountedBlue = styled(Counted)`
  background-image: url(${StreakBlue});
`;

export const CountedRed = styled(Counted)`
  background-image: url(${OutsIndicator});
`;