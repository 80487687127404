import styled from '@emotion/styled'
import { Background } from 'assets/images';
import { colors } from 'theme';

export const LoginContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${colors.grayDark};
  .login-left {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .login-form {
      width: 400px;
    }
  }
  .login-right {
    flex: 1;
    height: 100%;
    background: url(${Background});
    background-position: center;
  }
`;
