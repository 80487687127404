/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataContext } from "contexts/DataContextContainer";
import { ThemeContext, Themes } from 'contexts/ThemeContext';
import Theme from 'containers/Theme';
import DiceLeaderBoard from './dice';

function LeaderBoard(props) {
  const theme = useContext(ThemeContext);

  /** TODO: go back and fix dice leaderboard to use new HTML layout */
  if(theme === Themes.DICE) {
    return <DiceLeaderBoard {...props} />;
  } else {
    return <ThemedLeaderBoard {...props} />;
  }
}

function ThemedLeaderBoard({
  status = "play",
  teamPlayers = [],
  activeUser = {},
  onClickUser = () => {},
  styleModule = null,
  showWinningStatus = false
}) {
  const history = useHistory();
  const {
    authUser,
    maxRounds,
    setMaxRounds,
    moneyRank,
    endRound
  } = useContext(DataContext);
  const { round, tournament } = useParams();
  const [zIndex, setZIndex] = useState(0);
  const height = useRef(141);
  const allPlayers = useRef([]);

  useEffect(() => {
    let value = parseInt(window.localStorage.getItem('maxRounds') || maxRounds);
    window.localStorage.setItem('maxRounds', value);
    value != maxRounds && setMaxRounds(value);
  }, [maxRounds, setMaxRounds]);

  useEffect(() => {
    let a = [];
    for(let i=0; i < window.temp_players || 0; i++){
      a.push({
        id: 500 + i,
        total_score: 10,
        user: {
          user_id: 500 + i,
          is_host: false,
          email: 'test' + i + '@parlaygames.com',
          name: 'test' + i
        },
        information: {
          strikes: 0
        }
      });
    }
    if(window.is_last)
      allPlayers.current = a.concat(teamPlayers);
    else
      allPlayers.current = teamPlayers.concat(a);
  }, [teamPlayers]);

  useEffect(() => {
    if(showWinningStatus){
      height.current = Math.min(Math.max(allPlayers.current.length - 5, 0), 13) * 24 + 141;
      setZIndex(1);
    }
  }, [showWinningStatus]);

  const TeamPlayerRows = allPlayers.current.map((player, _i) => {
    const onClick = () => {
      onClickUser(player);
      history.push(
        `/tournament-view/${tournament}/${round}?userId=${player.id}`
      );
    };

    let rowClass = '';

    if (player.user.user_id === authUser.id) {
      rowClass = 'you';
      if(endRound && showWinningStatus){
        rowClass += ' flicker';
      }
    }

    if (activeUser && activeUser.id === player.id) {
      rowClass = `${rowClass} active`;
    }

    if (_i < moneyRank) {
      rowClass = `${rowClass} gold-coin`;
    }

    return (
    <tr key={_i} className={rowClass}>
      <td>{_i + 1}</td>
      <td>{player.user.user_id === authUser.id ? "You" : player.user.name}</td>
      <td>{player.total_score || 0}</td>
      <td>{player.information?.strikes || 0}</td>
      {status === 'waiting' && (
        <td onClick={onClick}>
          {activeUser?.id === player.id ? (
          <i className="far fa-eye watching"></i>
        ) : (
          <i className={player.user.user_id === authUser.id ? "" : "fas fa-eye"}></i>
        )}
        </td>
      )}
    </tr>);
  });

  return (
    <styleModule.Leaderboard height={height.current} zIndex={zIndex}>
      <div className="board-top">
        <h1>Leaderboard</h1>
        <div className="round">INNING <span>{round}</span> of <span>{maxRounds}</span></div>
        <span className="game-id">Game #23929273</span>
      </div>
      <div className="board-mid">
        <div className="scroll-bars">
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th><div>Name</div><div>$</div></th>
                <th>Points</th>
                <th>Outs</th>
                {status === 'waiting' && (
                  <th>View</th>
                )}
              </tr>
            </thead>
            <tbody>
              {TeamPlayerRows}
            </tbody>
          </table>
        </div>
      </div>
      <div className="board-bottom"></div>
    </styleModule.Leaderboard>
  );
}

export default Theme(LeaderBoard, 'containers/MultiPlayer/LeaderBoard');