/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, message, Space } from "antd";
import { BtnRoll } from "assets/images";
import query from "query-string";
import Number from "components/Number";
import SingleText from "components/SingleText";
import { DataContext } from "contexts/DataContextContainer";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  numberGroup,
  pairs,
  scoreOptions,
  statusColors,
  getFirstOrange,
  getFirstFreePick,
  isAutoPickToggle,
} from "service";

import Box from "components/Box";
import PointsModal from "components/PointsModal";
import Strikes from "components/Strikes";
import { SingleButton } from "components/SingleButton";
import customAxios from "service/customAxios";
import LeaderBoard from "../LeaderBoard";
import { socket } from "service/socket";
import OptionsHud from "components/OptionsHud";
import StrikeAnim from "components/StrikeAnim";
import BallLauncher from "components/BallLauncher";
import StreakCounter from "components/StreakCounter";
import BallIntro from "components/BallIntro";
import Hamburger from "components/Hamburger";
import BinaryToggle from "components/BinaryToggle";
import { ThemeContext, Themes } from "contexts/ThemeContext";
import moment from "moment";
import Theme from 'containers/Theme';
import gameEvents, { GAME_EVENT_TYPES } from 'service/game-events';

let stopTime = 1500;
const autoOrangeDelayTime = 5000;
const defaultFree = 10;

function Playing(props) {
  const {
    controller,
    globalThemeData,
    styleModule,
    themeSounds
  } = props;

  const history = useHistory();
  const { round, tournament } = useParams();
  const { god } = query.parse(history.location.search);
  const theme = useContext(ThemeContext);
  const {
    autoDiceRef,
    isAuto,
    setIsAuto,
    timeoutRef,
    authUser,
    curRollIndex,
    setCurRollIndex,
    skipping,
    setSkipping,
    endRound,
    setEndRound,
    maxRounds,
    setMaxRounds,
    teamPlayers,
    setTeamPlayers,
    additionalScore,
    setAdditionalScore,
    isPlayingSound,
    isPlayingMusic,
    streakStrikes,
    setStreakStrikes,
  } = useContext(DataContext);

  const [lastMoment, setLastMoment] = useState(moment());
  const [statuses, hookStatuses] = useState(Array.from({ length: 25 }, () => 0));
  const [activeBtn, setActiveBtn] = useState(true);
  const [holeTimes, setHoleTimes] = useState(0);
  const [isRolling, setIsRolling] = useState(false);
  const [rolledNum, setRolledNum] = useState();
  const [newWinGroupKeys, setNewWinGroupKeys] = useState([]);
  const [score, setScore] = useState(0);
  const [roundScore, setRoundScore] = useState(0);
  const [freeCount, setFreeCount] = useState(defaultFree);
  const [isFreePick, setIsFreePick] = useState(false);
  const [startedRound, setStartedRound] = useState(false);
  const [rolledDices, setRolledDices] = useState([]);
  const [showModalPoints, setShowModalPoints] = useState(false);
  const [numsAfterSkipping, setNumsAfterSkipping] = useState(null);
  const [isLastUser, setIsLastUser] = useState(false);
  const [showAnimationStrike, setShowAnimationStrike] = useState(false);
  const [hitStreak, setHitStreak] = useState(0);
  const [foulBallCount, setFoulBallCount] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const viewerCount = useRef(0);
  const previousStreakStrike = useRef(0);

  const [ playSoundClickButton ] = themeSounds.useSoundClickButton();
  const [ playSoundOrangeClick ] = themeSounds.useSoundOrangeClick();
  const [ playSoundRolling, { stop: stopSoundRolling } ] = themeSounds.useSoundRolling();
  const [ playSoundOrangeTileDelay ] = themeSounds.useSoundOrangeTileDelay();
  const [ playSoundCrowdCheer ] = themeSounds.useSoundCrowdCheer();
  const [ playSoundFanfare ] = themeSounds.useSoundFanfare();
  const [ playSoundOrgan1, { stop: stopSoundOrgan1 } ] = themeSounds.useSoundOrgan1();
  const [ playSoundOrgan2, { stop: stopSoundOrgan2 } ] = themeSounds.useSoundOrgan2();
  const [ playSoundOrgan3, { stop: stopSoundOrgan3 } ] = themeSounds.useSoundOrgan3();
  const [ playSoundSportsCrowd, { stop: stopSoundSportsCrowd } ] = themeSounds.useSoundSportsCrowd();
  const [ playFreePickSound ] = themeSounds.useSoundFreePick();
  const [ playFoulBall ] = themeSounds.useSoundFoulBall();
  const [ playSoundBackground, { stop: stopSoundBackground } ] = isPlayingMusic ? themeSounds.useBackgroundSoundAuto() : themeSounds.useBackgroundSound();
  const freePickStatusParams = {
    freeCount,
    hitStreak,
    foulBallCount,
    setHitStreak,
    setFoulBallCount,
  };

  if(socket && !isAuthorized) {

    const token = window.localStorage.getItem('token');
    if(token) {
      socket.emit("AUTHORIZE", { token: token });
      setIsAuthorized(true);
    }
  }

  const setStatuses = (newStatuses) => {
    let delay = 0;
    setTimeout(() => {
      hookStatuses(newStatuses);
    }, delay);
  };

  const counting = 20000;
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (isAutoPickToggle(event.target)) {
          return;
        }

        if (ref.current && !ref.current.contains(event.target)) {
          setIsAuto(false);
          clearTimeout(timeoutRef.current);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(autoDiceRef);

  if (theme !== Themes.DICE) {
    stopTime = previousStreakStrike.current < 2 ? 2500 : 5500;
  }

  const getNewGreenLines = (order) => {
    let newWinGroupKeys = pairs[order].filter((_key) => {
      let checkHasNotGreen = numberGroup[_key].find(
        (_order) =>
          order != parseInt(_order) &&
          statuses[parseInt(_order) - 1] != statusColors.blue &&
          statuses[parseInt(_order) - 1] != statusColors.green
      );
      if (!checkHasNotGreen) {
        return _key;
      }
    });
    return newWinGroupKeys;
  };

  const formatLastMoment = () => setLastMoment(moment());

  const getCountSuggestions = (order) => {
    return globalThemeData.numbersTemp.filter(
      (_number, _i) =>
        _number === globalThemeData.numbersTemp[order - 1] &&
        statuses[_i] === statusColors.orange
    ).length;
  };

  const isPochingo = (statuses) => {
    let cnt = statuses.filter(
      (_status) => _status != statusColors.green
    ).length;
    return cnt === 0;
  };

  const onClickBtnAuto = () => {
    setIsAuto(!isAuto);
  };

  const onClickRollBtn = () => {
    isPlayingSound && playSoundClickButton();
    let numbersToRoll = controller.getRandomNumbers();
    updateStatus(numbersToRoll);
    rollAll(numbersToRoll);
  };

  const updateStatus = async (numbersToRoll) => {
    const fullFoulBallCount = foulBallCount === defaultFree;
    const fullHitStreak = hitStreak === defaultFree;
    const freePickStatus = fullFoulBallCount || fullHitStreak;
    await apiUpdateStatus({
      information: {
        strikes: holeTimes,
        addScore: 0,
        freeCount: freeCount,
        rolling: !freePickStatus,
        rolledDices: numbersToRoll,
        skipping: skipping,
        isFreePick: freePickStatus,
        foulBallCount: fullFoulBallCount ? 0 : foulBallCount,
        hitStreak: fullHitStreak ? 0 : hitStreak,
        streakStrikes: streakStrikes,
        endRound: endRound
      },
      status: 2,
    });
  }

  const apiUpdateStatus = async (param) => {
    const postData = {
      ...(param.game_status && { game_status: param.game_status }),
      ...(param.total_score && { total_score: param.total_score }),
      ...(param.round_score && { round_score: param.round_score }),
      ...(param.roll_click && { roll_click: param.roll_click }),
      information: { ...param.information, rolledClick: rolledNum },
      status: param.status,
    };
    const { ok, data } = await customAxios.post(
      `api/v1/tournament/${tournament}/round/${round}/game`,
      postData
    );
    if (ok) {
      if (param.status === 3) {
        theme === Themes.DICE && message.success(`Round ${round} is over!`);
        if (data.round_complete) {
          theme === Themes.DICE && message.warn(`You are the last!`);
          setIsLastUser(true);
        }
        setEndRound(true);
        setIsAuto(false);
      }
    }
  };

  const toggleShowModalPoints = async () => {
    let timeout = 8000;

    if (streakStrikes < 2) {
      timeout = 2000;
    }

    setTimeout(() => {
      setShowModalPoints(true);
    }, timeout);
  };

  const onClickNumber = async (order) => {
    stopOrganSound();
    isPlayingSound && playSoundOrangeClick();
    isLastUser && setIsLastUser(false);
    isFreePick && setIsFreePick(false);
    let newWinnedLines = getNewGreenLines(order);
    setNewWinGroupKeys(newWinnedLines);

    let pochingo = false;
    let winnedIds = [];
    let addScore = 0;
    let postScore = score;
    let postRoundScore = roundScore;
    if (newWinnedLines.length > 0) {
      newWinnedLines.forEach((_groupKey) => {
        winnedIds = [...winnedIds, ...numberGroup[_groupKey]];
      });
      addScore = newWinnedLines.length * scoreOptions.greenLine;
      if(isPlayingSound){
        playSoundFanfare();
        playSoundCrowdCheer();
      }
    } else {
      addScore = scoreOptions.blueTile;
    }
    let newStatuses = statuses.map((_status, _i) => {
      if (winnedIds.includes((_i + 1).toString())) {
        return statusColors.green;
      } else {
        if (_status == statusColors.orange) {
          if (_i === order - 1) {
            return statusColors.blue;
          } else {
            return statusColors.white;
          }
        } else {
          if (_i === order - 1 && isFreePick) {
            return statusColors.blue;
          } else {
            return _status;
          }
        }
      }
    });

    if (isPochingo(newStatuses)) {
      pochingo = holeTimes === 3;
      if (holeTimes === 0) {
        addScore += 1000;
      } else if (holeTimes === 1) {
        addScore += 250;
      } else if (holeTimes === 2) {
        addScore += 50;
      }
    }
    setStatuses(newStatuses);
    postScore += addScore;
    postRoundScore += addScore;
    setAdditionalScore({
      addScore,
      order,
    });
    setRoundScore(postRoundScore);
    setScore(postScore);
    let numbersToRoll = controller.getRandomNumbers();
    const freePickStatus = controller.getFreePickStatus({...freePickStatusParams, streakCount: defaultFree});
    await apiUpdateStatus({
      game_status: newStatuses,
      total_score: postScore,
      round_score: postRoundScore,
      roll_click: [globalThemeData.numbersTemp[order - 1], order],
      information: {
        strikes: holeTimes,
        addScore: addScore,
        freeCount: freeCount,
        rolling: true,
        rolledDices: numbersToRoll,
        skipping: false,
        isFreePick: freePickStatus,
        foulBallCount: foulBallCount,
        hitStreak: hitStreak === defaultFree ? 0 : hitStreak,
        streakStrikes: streakStrikes,
        endRound: endRound
      },
      status: pochingo ? 3 : 2,
    });
    if (!pochingo) {
      if (freePickStatus) {
        setIsFreePick(true);
        setFreeCount(defaultFree);
      } else {
        rollAll(numbersToRoll);
      }
    }
  };

  const handleEndRoll = (number, streakStrikes) => {
    controller.handleEndRoll({
      streakStrikes,
      newWinGroupKeys,
      setNewWinGroupKeys,
      setIsRolling,
    });
  }

  useEffect(() => {
    if(isRolling && isPlayingSound) playSoundRolling();
    setTimeout(() => {
      if(isPlayingSound){
        if(isRolling){
          playOrganSound(curRollIndex % 4);
        }
      }else{
        stopOrganSound();
      }
    }, newWinGroupKeys.length ? 4000 : 0);
  }, [isPlayingSound, isRolling]);

  useEffect(() => {
    if (!isRolling && startedRound) {
      let numbersToRoll = controller.getRandomNumbers();
      let postStrikes = holeTimes;
      let postScore = null;
      let postRoundScore = null;
      let addScore = 0;
      let newStatuses = null;
      let postStatus = 2;
      let postSkipping = false;
      let postHitStreak = hitStreak;
      let postFoulBallCount = foulBallCount;
      let postFreeCount = freeCount - 1;
      setFreeCount(postFreeCount);

      // we got a "strike"
      if (rolledNum === 7) {
        postHitStreak = 0;
        setHitStreak(postHitStreak);
        postFreeCount = defaultFree;
        setFreeCount(defaultFree);
        setHoleTimes(++postStrikes);

        // check the number of "strikes" we have and do something
        if (postStrikes === 3) {
          postStatus = 3;
          setAdditionalScore(null);

        } else if(postStrikes === 1) {
          newStatuses = statuses.map((_status, _i) => {
            if (
              globalThemeData.numbersTemp[_i] === rolledNum &&
              _status == statusColors.white
            ) {
              return 3;
            }
            return _status;
          });

          setStatuses(newStatuses);
          if (isAuto && postStrikes === 2) {
            timeoutRef.current = setTimeout(() => {
              let numbersToRoll = controller.getRandomNumbers();
              rollAll(numbersToRoll);
            }, autoOrangeDelayTime);
          }
        }

      } else {
        if (
          statuses.filter(
            (_status, _i) =>
              globalThemeData.numbersTemp[_i] === rolledNum &&
              _status == statusColors.white
          ).length !== 0
        ) {
          let hasYellow = false;

          newStatuses = statuses.map((_status, _i) => {
            if (
              globalThemeData.numbersTemp[_i] === rolledNum &&
              _status == statusColors.white
            ) {
              hasYellow = true;
              return 3;
            }
            return _status;
          });

          if (hasYellow) {
            setHitStreak(++postHitStreak);
          }

          setStatuses(newStatuses);
        } else {
          setNumsAfterSkipping(numbersToRoll);
          addScore = scoreOptions.checkMark;
          postRoundScore = roundScore + addScore;
          postScore = score + addScore;
          postSkipping = true;
          setSkipping(postSkipping);
          setRoundScore(postRoundScore);
          setScore(postScore);
          setFoulBallCount(++postFoulBallCount);
        }
      }

      if (streakStrikes === 3) {
        addScore += 100;
        postScore = score + addScore;
        postRoundScore = roundScore + addScore;
        setScore(postScore);
      }

      apiUpdateStatus({
        ...(newStatuses && { game_status: newStatuses }),
        total_score: postScore,
        round_score: postRoundScore,
        roll_click: [rolledNum, 0],
        information: {
          strikes: postStrikes,
          addScore: addScore,
          freeCount: postFreeCount,
          rolling: isRolling,
          rolledDices: rolledDices,
          skipping: postSkipping,
          isFreePick: isFreePick,
          foulBallCount: postFoulBallCount,
          hitStreak: postHitStreak,
          streakStrikes: streakStrikes,
          endRound: endRound
        },
        status: postStatus,
      });
    }
    const interval = setInterval(() => {
      if (lastMoment.diff(moment()) / 1000 < -(counting / 1000 - 1)) {
        formatLastMoment();
        setIsAuto(true);
        clearTimeout(timeoutRef.current);
        clearInterval(interval);
      }
    }, counting);
    const intervalOrangeDelay = setInterval(() => {
      isPlayingSound && playSoundOrangeTileDelay();
    }, 15000);
    return () => {
      clearInterval(intervalOrangeDelay);
      clearInterval(interval);
    };
  }, [isRolling]);

  useEffect(() => {
    if (!isRolling && isAuto) {
      timeoutRef.current = setTimeout((auto) => {
        let firstOrange = getFirstOrange(statuses);
        if (firstOrange) {
          onClickNumber(firstOrange);
        }
      }, autoOrangeDelayTime);
    }
  }, [statuses]);

  const startNewRound = async (roundNum) => {

    if(!roundNum) {
      roundNum = parseInt(round) + 1;
    }
    const { ok } = await customAxios.post(
      `api/v1/tournament/${tournament}/round/${roundNum}`
    );
    if (ok) {
    }
  };
  
  useEffect(() => {
    if (isAuto) {
      let firstOrange = isFreePick ? getFirstFreePick(statuses) : getFirstOrange(statuses);
      if (firstOrange === 0) {
        onClickRollBtn();
      } else {
        onClickNumber(firstOrange);
      }
    }
  }, [isAuto]);

  useEffect(() => {
    if (isFreePick) {
      if(isPochingo(statuses)){
        rollAll(controller.getRandomNumbers());
      }else{
        isPlayingSound && playFreePickSound();
        if(isAuto){
          timeoutRef.current = setTimeout((auto) => {
            let firstFreePick = getFirstFreePick(statuses);
            if (firstFreePick) {
              onClickNumber(firstFreePick);
            }
          }, autoOrangeDelayTime);
        }
      }
    }
  }, [isFreePick]);

  useEffect(() => {
    if (showModalPoints) {
      stopOrganSound();
      const endRoundTimeOut = setTimeout(() => {
        setShowModalPoints(false);
        setStartedRound(false);
        setHoleTimes(0);
        setStreakStrikes(0);
        stopSoundBackground();
        history.push(`/tournament-view/${tournament}/${round}/?isLast=${isLastUser ? 'y' : 'n'}`);
      }, round < maxRounds ? 3000 : 1000);
      return () => clearTimeout(endRoundTimeOut);
    }
  }, [showModalPoints]);

  const rollDoneCallback = (numbersToRoll) => {
    setAdditionalScore(null);
    const number = theme === Themes.DICE ? globalThemeData.diceRollConverter(numbersToRoll) : numbersToRoll;

    setRolledNum(number);
    previousStreakStrike.current = streakStrikes;
    if (number === 7) {
      setShowAnimationStrike(true);
      if (streakStrikes === 2) {
        setRoundScore(roundScore + 100);
      }
      if(holeTimes === streakStrikes)
        setStreakStrikes(streakStrikes + 1);
    } else {
      streakStrikes && setStreakStrikes(0);
    }

    gameEvents.emit(GAME_EVENT_TYPES.END_ROLL, number, streakStrikes);
  };

  useEffect(() => {
    if(holeTimes === 2){
      const t = setTimeout(() => {
        onClickRollBtn();
      }, 3000);
      return () => clearTimeout(t);
    }
  }, [holeTimes]);

  useEffect(() => {
    if (showAnimationStrike) {
      let delay = 1500;

      if (streakStrikes >= 2) {
        delay = 4500;
      }

      const timer = setTimeout(() => {
        setShowAnimationStrike(false);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [showAnimationStrike]);

  const rollAll = (numbersToRoll) => {
    const isIntro = !holeTimes && activeBtn;
    setActiveBtn(false);
    setIsRolling(true);
    gameEvents.emit(GAME_EVENT_TYPES.BEGIN_ROLL);
    if (!endRound) {
      setCurRollIndex(curRollIndex + 1);
      setStartedRound(true);
      controller.rollDice({
        god,
        isIntro,
        numbersToRoll,
        rollDoneCallback,
        setRolledDices,
        stopSoundRolling,
      });
    }
  };

  const playOrganSound = (n) => {
    stopOrganSound();
    switch(n){
      case 0:
        playSoundOrgan1();
        break;
      case 1:
        playSoundOrgan2();
        break;
      case 2:
        playSoundOrgan3();
        break;
      default:
        playSoundSportsCrowd();
        break;
    }
  }

  const stopOrganSound = () => {
    stopSoundOrgan1();
    stopSoundOrgan2();
    stopSoundOrgan3();
    stopSoundSportsCrowd();
  }

  useEffect(() => {
    if (skipping) {
      setAdditionalScore({
        addScore: scoreOptions.checkMark,
        order: globalThemeData.numbersTemp.indexOf(rolledNum) + 1,
      });

      setTimeout(() => {
        isPlayingSound && playFoulBall();
      }, 1000);

      const timer = setTimeout(() => {
        setSkipping(false);
        const freePickStatus = controller.getFreePickStatus({...freePickStatusParams, streakCount: defaultFree});
        updateStatus(numsAfterSkipping);
        if (freePickStatus) {
          setIsFreePick(true);
          setFreeCount(defaultFree);
        }else{
          rollAll(numsAfterSkipping);
        }
      }, stopTime);
      return () => clearTimeout(timer);
    }
  }, [skipping]);

  const getTeamPlayers = async () => {
    const { ok, data } = await customAxios.get(
      `api/v1/tournament/${tournament}/round/${round}/game?get_all=true`
    );
    if (ok) {
      setTeamPlayers(data);
    }
  };

  useEffect(() => {
    viewerCount.current = teamPlayers.filter(
      (teamPlayer) => teamPlayer.status === 3 && teamPlayer.user.user_id !== authUser.id
    ).length;

    if (teamPlayers.length > 0) {
      socket.on("TOURNAMENT_GAME_STATUS", (data) => {
        setTeamPlayers(data);
      });
      return () => {
        socket.off("TOURNAMENT_GAME_STATUS");
      };
    } else {
      getTeamPlayers();
    }
  }, [teamPlayers]);

  useEffect(() => {
    if (isLastUser) {
      if (maxRounds > round) {
        startNewRound();
      }
    }
  }, [isLastUser]);

  const getAllData = async () => {
    const [teamResult, gameResult] = await Promise.all([
      await customAxios.get(
        `api/v1/tournament/${tournament}/round/${round}/game?get_all=true`
      ),
      await customAxios.get(
        `api/v1/tournament/${tournament}/round/${round}/game?get_all=false`
      ),
    ]);

    if (teamResult.ok) {
      setTeamPlayers(teamResult.data);
    }
    if (gameResult.ok) {
      setStatuses(
        gameResult.data?.game_status || Array.from({ length: 25 }, () => 0)
      );
      setHoleTimes(gameResult.data?.information?.strikes || 0);
      setScore(gameResult.data?.total_score || 0);
      setRoundScore(gameResult.data?.round_score || 0);
      setMaxRounds(gameResult.data.end_round);
      window.localStorage.setItem('maxRounds', gameResult.data.end_round);
      if (
        gameResult.data?.game_status &&
        gameResult.data.game_status.find(
          (_status) => _status == statusColors.orange
        )
      ) {
        setActiveBtn(false);
      }
    }
  };

  useEffect(() => {
    getAllData();
    history.push(history.location.pathname);
  }, [history.location.pathname, round]);

  useEffect(() => {
    if(history.action === 'POP'){
      history.go(1);
      history.push(history.location.pathname);
    }
  }, [history.action]);

  useEffect(() => {
    if (endRound) {
      setHitStreak(0);
      setFoulBallCount(0);
      toggleShowModalPoints();
    }
  }, [endRound]);

  useEffect(() => {
    setStatuses(Array.from({ length: 25 }, () => 0));
    setHoleTimes(0);
    setActiveBtn(true);
    previousStreakStrike.current = 0;
    setShowModalPoints(false);
  }, [round]);

  useEffect(() => {
    isPlayingMusic ? playSoundBackground() : stopSoundBackground();
  }, [isPlayingMusic]);

  useEffect(() => {
    window.scaleScreen();
    gameEvents.on(GAME_EVENT_TYPES.END_ROLL, handleEndRoll);
    return () => {
      gameEvents.off(GAME_EVENT_TYPES.END_ROLL, handleEndRoll);
    }
  }, []);

  let goButton = null;

  if (theme === Themes.DICE) {
    goButton = (
      <SingleButton
        renderTitle={() => (
          <SingleText
            size={32}
            color="#005822"
            weight={700}
            marginBottom={4}
            family="Barlow Semi Condensed Bold"
            filter="drop-shadow(0px 2px 0px rgba(170,234,127,0.8))"
          >
            {holeTimes > 0 ? "ROLL AGAIN" : "LET'S ROLL"}
          </SingleText>
        )}
        className="roll-btn"
        animate={holeTimes !== 3 && !startedRound ? true : false}
        width="225px"
        height="105px"
        bg={BtnRoll}
        onPress={onClickRollBtn}
      />
    );
  } else {
    goButton = <button className="start-btn" onClick={onClickRollBtn} />;
  }

  return (
    <styleModule.PlayingContainer className="main-game-content multi-wrapper">
      <Hamburger />
      <LeaderBoard teamPlayers={teamPlayers} status="play" />
      {rolledNum === 7 && <StrikeAnim show={showAnimationStrike} />}
      <Box className="rolling-wrapper">
        <div className="pane-container">
          {skipping && <div className="stop-loader" style={{animationDelay: (previousStreakStrike.current < 2 ? '1s' : '4s')}}></div>}
          {showModalPoints && <div className="pane-mask" />}
          <div className="pane">
            <styleModule.PaneRow>
              {statuses.map((_status, _i) => (
                <Col
                  key={_i}
                  className="gutter-row"
                  style={{
                    zIndex:
                      (additionalScore?.order === _i + 1 ||
                        rolledNum === globalThemeData.numbersTemp[_i]) &&
                      statuses.filter(
                        (_item, _j) =>
                          globalThemeData.numbersTemp[_j] == rolledNum &&
                          (_item == statusColors.white ||
                            _item == statusColors.orange)
                      ).length === 0 &&
                      skipping
                        ? 1
                        : 0,
                  }}
                >
                  <Fade delay={50 * _i} triggerOnce>
                    <Number
                      order={_i + 1}
                      status={_status}
                      isFreePick={isFreePick}
                      countRemained={getCountSuggestions(_i + 1)}
                      newWinGroupKeys={newWinGroupKeys}
                      rolledNum={rolledNum}
                      previousStreakStrike={previousStreakStrike.current}
                      onClick={() => onClickNumber(_i + 1)}
                    />
                  </Fade>
                </Col>
              ))}
            </styleModule.PaneRow>
          </div>
        </div>
        {skipping && <styleModule.FoulBallContainer delay={previousStreakStrike.current < 2 ? 1 : 4} />}
        <div
          className={
            "simple-flex game-strike-outs-container" +
            (!holeTimes && activeBtn ? "" : " show")
          }
        >
          {viewerCount.current > 0 && (
            <styleModule.ViewerCount className="viewer-count">
              <i className="far fa-eye"></i>
              <span className="plus">+</span>
              <span>{viewerCount.current}</span>
            </styleModule.ViewerCount>
          )}
          <StreakCounter
            outs={holeTimes}
            foulBall={foulBallCount}
            hitStreak={hitStreak}
          />
          <Strikes
            streakStrikes={streakStrikes}
            endRound={endRound}
          />
        </div>
        <BallIntro show={!holeTimes && activeBtn} round={round} />
        <div className="dices-section">
          <div className="free-count-down">
            <CircularProgressbar
              value={freeCount}
              maxValue={defaultFree}
              text={freeCount || "0"}
              background="blue"
              styles={buildStyles({
                trailColor: "#d6d6d6",
                backgroundColor: "#131753",
                pathColor: "#131753",
                textColor: "white",
                textSize: 32,
              })}
            />
          </div>
          {theme === Themes.DICE && (
            <styleModule.MyDicesContainer>
              <Space className="dice-container" size={15}>
                <div id="dice1" data-side="1">
                  <div className="sides side-1" />
                  <div className="sides side-2" />
                  <div className="sides side-3" />
                  <div className="sides side-4" />
                  <div className="sides side-5" />
                  <div className="sides side-6" />
                </div>
                <div id="dice2" data-side="1">
                  <div className="sides side-1" />
                  <div className="sides side-2" />
                  <div className="sides side-3" />
                  <div className="sides side-4" />
                  <div className="sides side-5" />
                  <div className="sides side-6" />
                </div>
              </Space>
            </styleModule.MyDicesContainer>
          )}
          {theme !== Themes.DICE && (
            <BallLauncher
              reload={isRolling}
              number={rolledNum}
              streakStrikes={streakStrikes}
              endRound={endRound}
              skipping={skipping}
            />
          )}
          {theme !== Themes.DICE && (
            <div className="auto-toggler">
              <h5>Auto Pick</h5>
              <BinaryToggle id="autopick" checked={isAuto} onChange={onClickBtnAuto} />
            </div>
          )}
        </div>
        <div className="btn-container">
          {activeBtn ? goButton : <Box width="100%" height="60px" />}
        </div>
        <OptionsHud />
      </Box>
      <PointsModal
        isLastUser={isLastUser}
        show={showModalPoints && round < maxRounds}
        round={round}
        maxRounds={maxRounds}
        roundScore={roundScore}
      />
    </styleModule.PlayingContainer>
  );
}

export default Theme(Playing, 'containers/MultiPlayer/Playing');