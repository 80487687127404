import React from "react";
import Playing from "containers/MultiPlayer/Playing";
import Waiting from "containers/MultiPlayer/Waiting";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import GlobalStyle from "theme/globalStyle";
import "react-circular-progressbar/dist/styles.css";
import "assets/webfonts/PinkSlab-130.ttf";
import "assets/webfonts/barlow-semi-condensed/BarlowSemiCondensed-Regular.ttf";
import "assets/css/fontawesome.min.css";
import "antd/dist/antd.css";
import { RouteMultiPlay } from "service/MyRoute";
import DataContextContainer from "contexts/DataContextContainer";
import Join from "containers/MultiPlayer/Join";
import Login from "containers/Login";
import Register from "containers/Register";
import Ready from "containers/MultiPlayer/Ready";
import Lobby from "containers/MultiPlayer/Lobby";
import RemoveTournament from "containers/Admin/RemoveTournament";
// import useSound from 'use-sound';
// import { SoundBackground } from "assets/audio";

import { ThemeContext } from 'contexts/ThemeContext';

function App() {

  // const [playSoundBackground, {isPlaying}] = useSound(SoundBackground, {volume: 0.5});

  // !isPlaying && playSoundBackground()

  const urlParams = new URLSearchParams(window.location.search);
  let currentTheme = urlParams.get('theme');  

  if(!currentTheme) {
    currentTheme = 'battingpractice';
  }

  return (
    <div className="App">
      <ThemeContext.Provider value={currentTheme}>
      <Scrollbars className="app-scrollbars" autoHide style={{ flex: 1 }}>
        <GlobalStyle />
        <Router>
          <Switch>
            <DataContextContainer>
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              {/* <RouteSinglePlay exact path="/rolling" component={Rolling} /> */}
              <RouteMultiPlay
                exact
                path="/tournaments/remove"
                component={RemoveTournament}
              />
              <RouteMultiPlay
                exact
                path="/tournaments/join"
                component={Join}
              />
              <RouteMultiPlay
                exact
                path="/tournaments/:tournament/:round"
                component={Playing}
              />
              <RouteMultiPlay
                exact
                path="/tournament-view/:tournament/:round"
                component={Waiting}
              />
              <RouteMultiPlay
                exact
                path="/tournament/ready/:tournament"
                component={Ready}
              />
              <RouteMultiPlay
                exact
                path="/tournaments/lobby"
                component={Lobby}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
            </DataContextContainer>
          </Switch>
        </Router>
      </Scrollbars>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
