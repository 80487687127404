/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import SingleText from "components/SingleText";
import React, { useContext, useEffect, useState } from "react";
import useSound from "use-sound";
import query from "query-string";
// import Countdown from "../Countdown";
import { useHistory, useParams } from "react-router-dom";
import customAxios from "service/customAxios";
import { Button } from "antd";
import { DataContext } from "contexts/DataContextContainer";
import { socket } from "service/socket";
import { colors } from "theme";
import { SoundClickButton } from "assets/audio";
import Theme from 'containers/Theme';

function Ready(props) {
  const { styleModule } = props;
  const history = useHistory();
  const {tournament} = useParams();
  const { authUser } = useContext(DataContext);
  const [play] = useSound(SoundClickButton);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [submittingPlay, setSubmittingPlay] = useState(false);
  const [submittingReady, setSubmittingReady] = useState(false);

  const { title, creator } = query.parse(history.location.search);

  const getTeamPlayers = async () => {
    const { ok, data } = await customAxios.get(
      `api/v1/tournament/${tournament}/player`
    );
    if (ok) {
      setTeamPlayers(data.players);
    }
  };

  const onClickPlay = async () => {
    play();
    setSubmittingPlay(true);
    const { ok } = await customAxios.post(
      `api/v1/tournament/${tournament}/round/1`
    );
    if (ok) {
    }
    setSubmittingPlay(false);
  };

  const onClickReady = async () => {
    play();
    setSubmittingReady(true);
    const { ok } = await customAxios.patch(
      `api/v1/tournament/${tournament}/player`
    );
    if (ok) {
    }
    setSubmittingReady(false);
  };

  const onGoBack = () => {
    play();
    history.goBack();
  };

  const onUnregister = async () => {
    play();
    const { ok } = await customAxios.delete(
      `api/v1/tournament/${tournament}/player`
    );
    if (ok) {
      history.replace("/tournaments/join");
    }
  };

  useEffect(() => {
    socket.on("TOURNAMENT_PLAYER_JOIN", (data) => {
      console.log("TOURNAMENT_PLAYER_JOIN", data)
      let tmpTeamPlayers = [...teamPlayers];
      tmpTeamPlayers.push(data);
      setTeamPlayers(tmpTeamPlayers);
    });
    return () => {
      socket.off("TOURNAMENT_PLAYER_JOIN");
    };
  }, [teamPlayers]);

  socket.on("TOURNAMENT_PLAYER_READY", (data) => {
    let tmpTeamPlayers = teamPlayers.map((_player) => {
      if (_player.id === data.id) {
        _player.status = 1;
      }
      return _player;
    });
    setTeamPlayers(tmpTeamPlayers);
  });

  useEffect(() => {
    getTeamPlayers();
  }, [history.location.pathname]);

  return (
    <styleModule.ReadyContainer className="ready-wrapper">
      <div className="ready-header">
        <SingleText>{title}</SingleText>
        <div className="ready-header-btns">
          <Button className="btn-goback" onClick={onGoBack}>
            Go Back
          </Button>
          <Button className="btn-unregister" onClick={onUnregister}>
            Unregister
          </Button>
        </div>
      </div>
      <div className="team-players-wrapper">
        {teamPlayers.length > 0 ? (
          teamPlayers.map((_player, _i) => (
            <div key={_i} className="team-player">
              <p>
                {authUser.id === _player.user.user_id
                  ? "You"
                  : _player.user.name}
              </p>
              <SingleText
                className="ready-status"
                color={_player.status === 1 ? colors.green : colors.redColor}
              >
                {_player.status === 1 ? "Ready" : "Not Ready"}
              </SingleText>
            </div>
          ))
        ) : (
          <div>No Players</div>
        )}
      </div>
      {authUser.id == creator ? (
        <Button
          className="btn-play"
          onClick={onClickPlay}
          loading={submittingPlay}
        >
          Play
        </Button>
      ) : (
        <Button
          className="btn-ready"
          onClick={onClickReady}
          loading={submittingReady}
        >
          Ready
        </Button>
      )}
    </styleModule.ReadyContainer>
  );
}

export default Theme(Ready, 'containers/MultiPlayer/Ready');
