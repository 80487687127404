import React, { useState } from "react";
import { useThemeStyles } from "service/theme";

export const MySwitch = ({
  ref,
  checked = false,
  checkedChildren = "ON",
  unCheckedChildren = "OFF",
  id = "",
  onChange = () => {},
}) => {
  const [styleModule, setStyleModule] = useState();

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'components/MySwitch');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------

  return (<styleModule.MySwitchContainer
    id={id}
    ref={ref}
    checkedChildren={checkedChildren}
    unCheckedChildren={unCheckedChildren}
    checked={checked}
    onChange={onChange}
  />);
};
