import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from 'contexts/DataContextContainer';
import ApngComponent from 'react-apng';
import { BallIn, BallOut } from 'assets/images/battingpractice';
import Theme from 'containers/Theme';

function BallIntro(props) {
  const styleModule = props.styleModule;
  const themeSounds = props.themeSounds;

  const ballsIn = useRef();
  const ballsOut = useRef();
  const { isPlayingSound } = useContext(DataContext);
  const [isShown, setIsShown] = useState(false);
  const [ playSoundAnthem, { stop: stopSoundAnthem }] = themeSounds.useSoundAnthem();

  useEffect(() => {
    const t = setTimeout(() => {
      if(isShown !== props.show){
        if(props.show){
          ballsIn.current.one();
        }else{
          ballsOut.current.one();
        }
        if(parseInt(props.round) === 1){
          if(props.show && isPlayingSound)
            playSoundAnthem();
          else
            stopSoundAnthem();
        }
        setIsShown(props.show);
      }
    }, 500);
    return () => {
      clearTimeout(t);
    }
  });

  useEffect(() => {
    !isPlayingSound && stopSoundAnthem();
  }, [isPlayingSound, stopSoundAnthem]);

  return(
    <styleModule.StyleContainer className={!props.show ? 'balls-out' : ''}>
      <ApngComponent ref={ballsIn} style={{ display: props.show ? 'block' : 'none' }} className='balls' src={BallIn} rate={1} />
      <ApngComponent ref={ballsOut} style={{ display: !props.show ? 'block' : 'none' }} className='balls' src={BallOut} rate={1} />
    </styleModule.StyleContainer>
  );
}

export default Theme(BallIntro, 'components/BallIntro');