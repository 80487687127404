import React, { useState } from 'react';
import { useThemeStyles } from "service/theme";
import { Zoom } from "react-awesome-reveal";

const PointsModal = (props) => {
  const { show, roundScore, isLastUser, round, maxRounds } = props;
  const [styleModule, setStyleModule] = useState();

  useThemeStyles(setStyleModule, 'components/PointsModal');

  if (!show) {
    return null;
  }

  if (isLastUser && round >= maxRounds) {
    return null;
  }

  if (!styleModule) {
    return null;
  }

  return (
    <styleModule.Wrapper isLastUser={isLastUser}>
      <Zoom duration={300} className="modal-points">
        <div className="points-wrapper">
          <p className="points-score">{roundScore} Points</p>
        </div>
      </Zoom>
    </styleModule.Wrapper>
	);
}

export default PointsModal;