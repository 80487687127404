import React, { useState } from 'react'
import { keyframes } from "@emotion/core";
import Reveal from "react-awesome-reveal";
import { useThemeStyles } from "service/theme";

export const Hole = ({hole, holeTimes, index}) => {
  const [styleModule, setStyleModule] = useState();

  const activeAnimation = keyframes`
    0% { transform: scale( 5 ); }
    100% { transform: scale( 1 ); }
  `;

  const inactiveAnimation = keyframes`

  `;

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setStyleModule, 'components/Hole');

  if(!styleModule) {
    return null;
  }
  // -------------------------------------

  return (
    <Reveal keyframes={holeTimes === hole ? activeAnimation : inactiveAnimation} triggerOnce>
      <styleModule.HoleContainer className={`hole`}>
        {holeTimes >= hole && <i className="fas fa-times"></i>}
      </styleModule.HoleContainer>
    </Reveal>
  )
};