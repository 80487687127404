
import React, { useContext } from "react";
import { ThemeContext } from 'contexts/ThemeContext';
import {
  Counted,
  CountedBlue,
  CountedContainer,
  StreakCounterContainer,
  Label,
  EmptySlots
} from 'components/StreakCounter/theme/battingpractice/style'
import { Themes } from "contexts/ThemeContext";

const Counter = (props) => {
  const { count, label, type } = props;
  const theme = useContext(ThemeContext);

  // This component should only rendered for baseball bingo
  if (theme !== Themes.BATTINGPRACTICE) {
    return null;
  }

  let counterIcon = <Counted />;

  if (type === 'blue') {
    counterIcon = <CountedBlue />;
  }

  return (
    <StreakCounterContainer>
      <Label>{label}</Label>
      <EmptySlots />
      <CountedContainer>
        {Array(count).fill(null).map(() => (counterIcon))}
      </CountedContainer>
    </StreakCounterContainer>
  )
};

export default Counter;