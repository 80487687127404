import React from 'react'
import { Route } from 'react-router-dom';
import LayoutMulti from 'layouts/LayoutMulti';

const RouteMultiPlay = ({ component: Component, ...rest }) => {

  return (
    <Route {...rest} render={(props) => (
      <LayoutMulti><Component {...props} /></LayoutMulti>
    )} />
  )
}

export { RouteMultiPlay };
