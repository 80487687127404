import React, { useState, useContext } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import { Button, Popover, Switch } from 'antd';
import Theme from 'containers/Theme';

const Hamburger = (props) => {
  const {
    isPlayingMusic,
    isPlayingSound,
    setIsPlayingSound,
    setIsPlayingMusic,
  } = useContext(DataContext)
  const { styleModule } = props;
	const [visible, setVisible] = useState(false);

	const options = <div>
		<styleModule.SwitchContainer>
			<div>Sound</div>
			<Switch checked={isPlayingSound} size="small" onChange={setIsPlayingSound}/>
		</styleModule.SwitchContainer>
		<styleModule.SwitchContainer>
			<div>Music</div>
			<Switch checked={isPlayingMusic} size="small" onChange={setIsPlayingMusic}/>
		</styleModule.SwitchContainer>
	</div>;

	const handleVisibilityChange = () => {
		setVisible(!visible);
	}

	return (
		<styleModule.Wrapper role="button">
			<Popover
				visible={visible}
				content={options}
			>
				
				<Button onClick={handleVisibilityChange} type="primary" icon={<i className="menu-button"/>} />
			</Popover>
		</styleModule.Wrapper>
	);
}

export default Theme(Hamburger, 'components/Hamburger');