/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { Col, Space } from "antd";
import query from "query-string";
import SingleText from "components/SingleText";
import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import LeaderBoard from "../LeaderBoard";
import MultiNumber from "components/MultiNumber";
import { socket } from "service/socket";
import { useHistory, useParams } from "react-router-dom";
import customAxios from "service/customAxios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { DataContext } from "contexts/DataContextContainer";
import { useThemeStyles } from "service/theme";
import RoundEndWinner from "components/RoundEndWinner";
import Theme from 'containers/Theme';
import { ThemeContext, Themes } from "contexts/ThemeContext";
import Hamburger from "components/Hamburger";
import StreakCounter from "components/StreakCounter";
import Strikes from "components/Strikes";
import BallLauncher from "components/BallLauncher";
import NextModal from "components/NextModal";

const stopTime = 1500;

function Waiting(props) {
  const { styleModule, themeSounds } = props;
  const history = useHistory();
  const { round, tournament } = useParams();
  const { userId, isLast } = query.parse(history.location.search);
  const [activeUser, setActiveUser] = useState({});
  const [showWinningStatus, setShowWinningStatus] = useState(false);
  const [playingStyleModule, setPlayingStyleModule] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);
  const {
    teamPlayers,
    setTeamPlayers,
    endRound,
    isPlayingMusic,
    authUser,
  } = useContext(DataContext);
  const theme = useContext(ThemeContext);

  const [ playSoundBackground, { stop: stopSoundBackground } ] = isPlayingMusic ? themeSounds.useBackgroundSoundAuto() : themeSounds.useBackgroundSound();

  const onClickUser = (user) => {
    setActiveUser(user);
  };

  const rollDice = (diceValues) => {
    let dice1 = document.getElementById("dice1");
    let dice2 = document.getElementById("dice2");
    dice1.dataset.side = diceValues[0];
    dice2.dataset.side = diceValues[1];
    dice1.classList.toggle("reRoll");
    dice2.classList.toggle("reRoll");
  };

  const getGameEndAnimation = () => {
    let animation = null;
    const rank = teamPlayers.findIndex(player => player.user.user_id === authUser.id);
    const score = teamPlayers.find(player => player.user.user_id === authUser.id).total_score;
    animation = <RoundEndWinner rank={rank + 1} prize={score} hooks={{setShowWinningStatus, setShowNextModal}} />;
    return animation;
  };
  
  useEffect(() => {
    window.scaleScreen();
    setShowNextModal(false);

    socket.on("TOURNAMENT_OTHER", (data) => {
      console.log("TOURNAMENT_OTHER", data);
    });

    socket.on("TOURNAMENT_COMPLETE", (data) => {
      console.log("TOURNAMENT_COMPLETE", data);
      setShowWinningStatus(true);
    });
    
    return () => {
      socket.off("TOURNAMENT_OTHER");
      socket.off("TOURNAMENT_COMPLETE");
    };
  }, []);

  useEffect(() => {
    const getAllData = async () => {
      const [teamResult, roundStatusResult, tournamentResult] = await Promise.all(
        [
          await customAxios.get(
            `api/v1/tournament/${tournament}/round/${round}/game?get_all=true`
          ),
          await customAxios.get(`api/v1/tournament/${tournament}/round/${round}`),
          await customAxios.get(`api/v1/tournament/${tournament}`),
        ]
      );
      if (teamResult.ok) {
        setTeamPlayers(teamResult.data);
        let playingUsers = teamResult.data.filter(
          (_player) => _player.status == 2
        );
        if (playingUsers.length > 0) {
          setActiveUser(playingUsers[0]);
        } else {
          setActiveUser(teamResult.data[0]);
        }
      }
      if (tournamentResult.ok && tournamentResult.data.status === 3) {
        await callSocketEndTournament();
      }
      if (roundStatusResult.ok && roundStatusResult.data.status === 3) {
        await callSocketEndRound();
      }
    };

    const callSocketEndRound = async () => {
      await customAxios.post(`api/v1/tournament/${tournament}/socket`, {
        data: {
          completed_round: true,
        },
        including_me: true,
      });
    };
  
    const callSocketEndTournament = async () => {
      await customAxios.post(`api/v1/tournament/${tournament}/complete`);
    };

    getAllData();
  }, [history.location.pathname, round, tournament, setTeamPlayers]);

  useEffect(() => {
    if (userId) {
      setActiveUser(teamPlayers.find((_player) => userId == _player.id));
    }else if (isLast === 'y') {
      setActiveUser(teamPlayers.find((_player) => _player.user.user_id === authUser.id));
    }else{
      let playingUsers = teamPlayers.filter((_player) => _player.status == 2);
      if (playingUsers.length > 0) {
        setActiveUser(playingUsers[0]);
      } else {
        setActiveUser(prevActiveUser => teamPlayers.find((_player) => _player.id === prevActiveUser.id));
      }
    }
  }, [userId, isLast, teamPlayers, authUser]);

  useEffect(() => {
    if (teamPlayers.length > 0) {
      socket.on("TOURNAMENT_GAME_STATUS", (data) => {
        console.log("TOURNAMENT_GAME_STATUS", data);
        setTeamPlayers(data);
      });
      return () => {
        socket.off("TOURNAMENT_GAME_STATUS");
      };
    }
  }, [teamPlayers, setTeamPlayers]);

  useEffect(() => {
    if (
      activeUser &&
      activeUser.information &&
      activeUser.information.rolledDices &&
      activeUser.information.skipping &&
      !activeUser.information.isFreePick
    ) {
      if (activeUser.information.skipping) {
        const rollTimeOut = setTimeout(() => {
          rollDice(activeUser.information.rolledDices);
        }, stopTime);
        return () => clearTimeout(rollTimeOut);
      } else {
        rollDice(activeUser.information.rolledDices);
      }
    }
  }, [activeUser]);

  useEffect(() => {
    isPlayingMusic ? playSoundBackground() : stopSoundBackground();
    return () => stopSoundBackground();
  }, [isPlayingMusic, endRound, playSoundBackground, stopSoundBackground]);

  // Dynamic import of theme style
  // -------------------------------------
  useThemeStyles(setPlayingStyleModule, "containers/MultiPlayer/Playing");

  if (!playingStyleModule) {
    return null;
  }
  // -------------------------------------

  return (
    <styleModule.WaitingContainer className="multi-wrapper">
      <Hamburger />
      <LeaderBoard
        teamPlayers={teamPlayers}
        status="waiting"
        activeUser={activeUser}
        onClickUser={onClickUser}
        showWinningStatus={showWinningStatus}
      />
      <div className="multi-body">
        <div className="multi-pane-header">
          <SingleText size={18} weight={500}>
            {activeUser?.user?.name}
          </SingleText>
          <div className="multi-strikes-wrapper">
            {Array.from({ length: 3 }, (v, i) => i + 1).map((_hole, _j) => (
              <div key={_j} className={`multi-strike`}>
                {activeUser?.information &&
                  activeUser?.information?.strikes >= _hole && (
                    <i className="fas fa-times"></i>
                  )}
              </div>
            ))}
          </div>
        </div>
        <styleModule.MultiPaneContainer>
          <div className="pane">
            <styleModule.PaneRow>
              {activeUser &&
                (
                  activeUser.game_status || Array.from({ length: 25 }, () => 0)
                ).map((_status, _i) => (
                  <Col key={_i} className="gutter-row">
                    <Fade delay={isLast === 'y' ? 0 : 50 * _i} triggerOnce>
                      <MultiNumber
                        order={_i + 1}
                        status={_status}
                        isFreePick={activeUser?.information?.isFreePick}
                        rolledNum={
                          activeUser.information &&
                          activeUser.information.rolledDices
                            ? activeUser.information.rolledDices[0] +
                              activeUser.information.rolledDices[1]
                            : null
                        }
                        rolledClick={
                          activeUser.information &&
                          activeUser.information.rolledClick
                            ? activeUser?.information.rolledClick
                            : 0
                        }
                        skipping={
                          activeUser.information &&
                          activeUser.information.skipping
                        }
                      />
                    </Fade>
                  </Col>
                ))}
            </styleModule.PaneRow>
          </div>
        </styleModule.MultiPaneContainer>
        <div className="simple-flex game-strike-outs-container">
          <StreakCounter
            outs={activeUser?.information?.strikes}
            foulBall={activeUser?.information?.foulBallCount}
            hitStreak={activeUser?.information?.hitStreak}
          />
          <Strikes
            streakStrikes={activeUser?.information?.streakStrikes}
            endRound={activeUser?.information?.endRound}
            watchMode={true}
          />
        </div>
        <div className="dices-section">
          <div className="free-count-down">
            <CircularProgressbar
              value={activeUser?.information?.freeCount || 10}
              maxValue={10}
              text={activeUser?.information?.freeCount || 10}
              background="blue"
              styles={buildStyles({
                trailColor: "#d6d6d6",
                backgroundColor: "#131753",
                pathColor: "#131753",
                textColor: "white",
                textSize: 32,
              })}
            />
          </div>
          <playingStyleModule.MyDicesContainer>
            <Space size={15}>
              <div id="dice1" data-side="1">
                <div className="sides side-1" />
                <div className="sides side-2" />
                <div className="sides side-3" />
                <div className="sides side-4" />
                <div className="sides side-5" />
                <div className="sides side-6" />
              </div>
              <div id="dice2" data-side="1">
                <div className="sides side-1" />
                <div className="sides side-2" />
                <div className="sides side-3" />
                <div className="sides side-4" />
                <div className="sides side-5" />
                <div className="sides side-6" />
              </div>
            </Space>
          </playingStyleModule.MyDicesContainer>
          {theme !== Themes.DICE && (
            <BallLauncher
              reload={activeUser?.information?.rolling}
              number={activeUser?.information?.rolledDices}
              streakStrikes={activeUser?.information?.streakStrikes}
              endRound={activeUser?.information?.endRound}
              skipping={activeUser?.information?.skipping}
              watchMode={true}
            />
          )}
        </div>
      </div>
      <styleModule.SpectatorMode>
        {activeUser?.user?.name}
      </styleModule.SpectatorMode>
      {showWinningStatus && <div className="overlay overlay--in"></div>}
      {showNextModal && <div className="overlay"></div>}
      {showWinningStatus && getGameEndAnimation()}
      {showNextModal && <NextModal />}
    </styleModule.WaitingContainer>
  );
}

export default Theme(Waiting, 'containers/MultiPlayer/Waiting');