/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import SingleText from "components/SingleText";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { colors } from "theme";
import { DataContext } from "contexts/DataContextContainer";
import { useThemeStyles } from "service/theme";

const tableHeaderLabelSize = 10;
const tableBodyValueSize = 12;

function LeaderBoard(props) {
  const [styleModule, setStyleModule] = useState();

  useThemeStyles(setStyleModule, 'containers/MultiPlayer/LeaderBoard');

  if(!styleModule) {
    return null;
  }

  return <ThemedLeaderBoard {...props} styleModule={styleModule} />;
}

function ThemedLeaderBoard({
  status = "play",
  teamPlayers = [],
  activeUser = {},
  onClickUser = () => {},
  styleModule = null
}) {
  const history = useHistory();
  const { authUser, maxRounds } = useContext(DataContext);
  const { round, tournament } = useParams();

  const TableRow = ({ player, className, ranking, onClickEye = () => {} }) => (
    <div
      className={`table-row info-row ${className} ${
        player?.user.user_id == authUser.id && "you"
      }`}
    >
      <div className="table-col col1">
        <div className={`ranking-num ${player.status == 3 && "done"}`}>
          {ranking}
        </div>
      </div>
      <div className="table-col col2">
        <SingleText
          size={tableBodyValueSize}
          weight={500}
          color={player.status == 3 ? colors.redColor : "white"}
        >
          {player?.user.user_id == authUser.id ? "You" : player.user?.name}
        </SingleText>
      </div>
      {/* {status === "play" &&
      player?.user.user_id == authUser.id &&
      player?.information?.addScore &&
      player?.information?.addScore != 0 ? (
        <div className="table-col additional-score">
          <SingleText size={tableBodyValueSize} weight={500} marginRight={10}>
            +{player?.information?.addScore}
          </SingleText>
        </div>
      ) : (
        <div></div>
      )} */}
      <div className="table-col col3">
        <div className="value-rect value-points">
          <SingleText size={tableBodyValueSize} weight={500}>
            {player.total_score || 0}
          </SingleText>
        </div>
      </div>
      <div className="table-col col5">
        <div className={`strike-rect`}>
          <SingleText
            size={tableBodyValueSize}
            color={colors.redColor}
            weight={500}
          >
            {player.information?.strikes || 0}
          </SingleText>
        </div>
      </div>
      {status === "waiting" && (
        <div className="table-col col4 cursor" onClick={onClickEye}>
          {activeUser?.id == player.id ? (
            <i className="far fa-eye watching"></i>
          ) : (
            <i className="fas fa-eye"></i>
          )}
        </div>
      )}
    </div>
  );
  
  return (
    <styleModule.MainLeaderboardDiv>
      <div className="multi-gameid">
        <SingleText size={11} weight={500} marginBottom={10}>
          Game #23929273
        </SingleText>
      </div>
      <styleModule.LeaderBoardContainer>
        {/* <div className="leaderboard-menu" onClick={() => setStrikeMode(!strikeMode)}>
          <SingleAntImage src={strikeMode ? Menu1 : Menu} width={42} height={40} />
        </div> */}
        <div className="match-title">
          <SingleText size={10} color={"#efd63a"} weight="bold">
            (Round {round} of {maxRounds})
          </SingleText>
        </div>
        <div className="leaderboard-match-table">
          <div className="table-row match-table-header">
            <div className="table-col col1">
              <SingleText size={tableHeaderLabelSize} color="#8484e1" weight="bold">
                RANK
              </SingleText>
            </div>
            <div className="table-col col2">
              <SingleText
                size={tableHeaderLabelSize}
                color="#8484e1"
                weight="bold"
              >
                NAME
              </SingleText>
            </div>
            <div className="table-col col3">
              <SingleText
                size={tableHeaderLabelSize}
                color="#8484e1"
                weight="bold"
                align="center"
              >
                POINTS
              </SingleText>
            </div>
            <div className="table-col col5">
              <SingleText
                size={tableHeaderLabelSize}
                color="#8484e1"
                weight="bold"
              >
                STRIKES
              </SingleText>
            </div>
            {status === "waiting" && (
              <div className="table-col col4">
                <SingleText
                  size={tableHeaderLabelSize}
                  color="#8484e1"
                  weight="bold"
                >
                  Watch
                </SingleText> 
              </div>
            )}
          </div>
          <Scrollbars autoHide style={{ flex: 1 }}>
            <div>
              {teamPlayers.length > 0 &&
                teamPlayers.map((_player, _i) => (
                  <TableRow
                    key={_i}
                    ranking={_i + 1}
                    player={_player}
                    onClickEye={() => {
                      onClickUser(_player);
                      history.push(
                        `/tournament-view/${tournament}/${round}?userId=${_player.id}`
                      );
                    }}
                  />
                ))}
            </div>
          </Scrollbars>
        </div>
      </styleModule.LeaderBoardContainer>
    </styleModule.MainLeaderboardDiv>
  );
}

export default LeaderBoard;