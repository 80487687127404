import SingleText from "components/SingleText";
import React, { useContext, useState } from "react";
import { Form, Input, Button } from "antd";
import { LoginContainer } from "./style";
import { Link, useHistory } from "react-router-dom";
import customAxios, { setAuthHeader } from "service/customAxios";
import { setJwt } from "service/jwt";
import { DataContext } from "contexts/DataContextContainer";
import { socket } from "service/socket";

export default function Login() {
  const history = useHistory();
  const { setAuthUser } = useContext(DataContext);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values) => {
    setSubmitting(true);
    const { ok, data } = await customAxios.post("api/v1/auth/login", values);
    if (ok) {

      window.localStorage.setItem('token', data.access_token);
      
      setJwt(data.access_token);
      setAuthHeader(data.access_token);
      setAuthUser(data.user);

      if (socket) {
        socket.emit("AUTHORIZE", { token: data.access_token },
          (response) => {
            console.log(response.status); // ok
          }
        );
      }
      
      history.replace("/tournaments/join");
    }
    setSubmitting(false);
  };

  return (
    <LoginContainer>
      <div className="login-left">
        <SingleText size={30}>Login</SingleText>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Invalide email!" },
            ]}
          >
            <Input placeholder="email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Link className="login-form-forgot" to="#/">
              Forgot password
            </Link>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={submitting}
            >
              Log in
            </Button>
            Or <Link to="/register">register now!</Link>
          </Form.Item>
        </Form>
      </div>
      <div className="login-right">sdfsdf</div>
    </LoginContainer>
  );
}
