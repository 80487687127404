export const handleEndRoll = ({
	newWinGroupKeys,
	setNewWinGroupKeys,
	setIsRolling
}) => {
	setIsRolling(false);
	newWinGroupKeys.length > 0 && setNewWinGroupKeys([]);
}

export const getRandomNumbers = () => {
  let result1 = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
  let result2 = Math.floor(Math.random() * (6 - 1 + 1)) + 1;

  return [result1, result2];
}

export const getFreePickStatus = ({
	streakCount,
	freeCount,
}) => {
	if (streakCount === 10 && freeCount === 0) {
		return true;
	}

	if (streakCount === 9 && freeCount === 1) {
		return true;
	}

	return false;
}

export const rollDice = ({
	isIntro,
	numbersToRoll,
	rollDoneCallback,
	setRolledDices,
	stopSoundRolling,
}) => {
	let dice1 = document.getElementById("dice1");
	let dice2 = document.getElementById("dice2");
	let result1 = numbersToRoll
		? numbersToRoll[0]
		: Math.floor(Math.random() * (6 - 1 + 1)) + 1;
	let result2 = numbersToRoll
		? numbersToRoll[1]
		: Math.floor(Math.random() * (6 - 1 + 1)) + 1;

	dice1.dataset.side = result1;
	dice2.dataset.side = result2;
	dice1.classList.toggle("reRoll");
	dice2.classList.toggle("reRoll");

	setRolledDices([result1, result2]);

	let delay = 4000;

	if (!isIntro) {
		delay = 2000;
	}

	const rollTimeOut = setTimeout(
		() => {
			stopSoundRolling();
			rollDoneCallback(result1, result2);
		},
		delay
	);

	return () => clearTimeout(rollTimeOut);
};