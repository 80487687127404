// import { sizes } from "service";
import digitalFont from "assets/webfonts/Digital-Dismay.otf";
import varsityTeam from "assets/webfonts/VarsityTeam-Bold.otf";
import liquidCrystal from "assets/webfonts/liquid_crystal/LiquidCrystal-Normal.otf";
import barlowSemiCondensedBold from "assets/webfonts/barlow-semi-condensed/BarlowSemiCondensed-Bold.ttf";
import barlowSemiCondensedMedium from "assets/webfonts/barlow-semi-condensed/BarlowSemiCondensed-Medium.ttf";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('/fonts/roboto-v27-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v27-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('/fonts/roboto-v27-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v27-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v27-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('/fonts/roboto-v27-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v27-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/roboto-v27-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v27-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* just-another-hand-regular - latin */
@font-face {
  font-family: 'Just Another Hand';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/just-another-hand-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/just-another-hand-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/just-another-hand-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/just-another-hand-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/just-another-hand-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/just-another-hand-v12-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'PTC55F';
    src: url(${digitalFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Varsity Team Bold';
    src: url(${varsityTeam}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Liquid Crystal';
    src: url(${liquidCrystal}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${barlowSemiCondensedBold}) format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${barlowSemiCondensedMedium}) format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  body {
    font-family: 'Roboto';
    margin: 0;
    height: 100%;
  }
  #bgVideo {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .App {
    height: 100vh;
  }
  .cursor {
    cursor: pointer;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  h1, h2, h3, h4, h5, h6, p, span, text {
    -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  }
  .modal-win {
    .ant-modal {
      width: 400px !important;
      .ant-modal-content {
        background-color: transparent;
        box-shadow: unset;
        .ant-modal-body {
          .win-stars-wrapper {
            width: 100%;
            height: 250px;
            .stars-container {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .win-ray {
              position: absolute;
              top: -100px;
              z-index: 0;
              animation-name: spin;
              animation-duration: 5000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              @keyframes spin {
                from {
                    transform:rotate(0deg);
                }
                to {
                    transform:rotate(360deg);
                }
              }
            }
            .win-mark {
              /* position: absolute; */
              /* width: $/{sizes.mainWidth - 140}px; */
              height: 170px;
              background-size: cover;
              top: -88px;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                filter: drop-shadow(0px 5px 2.5px rgba(255,255,255,0.4));
              }
              .win-money {
                position: relative;
                text-align: center;
                bottom: 32px;
              }
            }
            .option-btns {
              display: flex;
              z-index: 2;
            }
          }
        }
      }
    }
  }
  .modal-first-screen {
    .ant-modal {
      width: 320px !important;
      .ant-modal-content {
        background-color: transparent;
        box-shadow: unset;
        .ant-modal-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  
  .modal-confirm {
    .ant-modal {
      width: 320px !important;
      .ant-modal-content {
        background-color: rgba(0, 0, 0, 0.7);
        box-shadow: unset;
        border-radius: 10px;
        border: solid dimgray 1px;
        .ant-modal-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .logs-drawer {
    .ant-drawer-content-wrapper {
      background-color: transparent;
      .ant-drawer-content {
        background-color: transparent;
        .ant-drawer-header {
          background: transparent;
          border-color: transparent;
          .ant-drawer-title {
            color: white;
            font-size: 32px;
            font-weight: 700;
          }
          .ant-drawer-close {
            color: white;
          }
        }
      }
    }
  }
`;

export default GlobalStyle;
