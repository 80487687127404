import React, { useState } from "react";
import {
  useThemController,
  useThemeStyles,
  useThemeSounds,
  useGlobalThemeData
} from "service/theme";

const Theme = (Component, namespace) => {
  const Render = (props) => {
    const [controller, setController] = useState();
    const [styleModule, setStyleModule] = useState();
    const [themeSounds, setThemeSounds] = useState();
    const [globalThemeData, setGlobalThemeData] = useState();

    useThemController(setController);
    useThemeStyles(setStyleModule, namespace);
    useThemeSounds(setThemeSounds, namespace);
    useGlobalThemeData(setGlobalThemeData);

    if(!styleModule || !themeSounds || !globalThemeData || !controller) {
      return null;
    }

    return (
      <Component
        {...props}
        controller={controller}
        styleModule={styleModule}
        themeSounds={themeSounds}
        globalThemeData={globalThemeData}
      />
    )
  }

  return Render;
}

export default Theme;