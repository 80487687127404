/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Row, InputNumber } from "antd";
import SingleText from "components/SingleText";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import customAxios from "service/customAxios";
import { DataContext } from "contexts/DataContextContainer";
import { socket } from "service/socket";
import useSound from "use-sound";
import { SoundClickButton } from "assets/audio";
import Theme from 'containers/Theme';

function Join(props) {
  const { styleModule } = props;
  const history = useHistory();
  const [play] = useSound(SoundClickButton);
  const { authUser } = useContext(DataContext);
  const [tournaments, setTournaments] = useState([]);
  const [newTournamet, setNewTournament] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [totalRounds, setTotalRounds] = useState(3);
  const [joinedTournaments, setJoinedTournaments] = useState([]);

  const onCreateGame = async () => {
    play()
    setIsCreating(true);
    const { ok, data } = await customAxios.post("api/v1/tournament", {
      name: newTournamet,
      end_round: totalRounds,
    });
    setIsCreating(false);
    if (ok) {
      gotoReadyScreen(data.id, newTournamet, authUser.id);
    }
  };

  const onChangeMaxRounds = (value) => {
    play()
    setTotalRounds(value);
  };

  const gotoReadyScreen = (tournament_id, tournament_name, creator_id) => {
    play()
    history.push(
      `/tournament/ready/${tournament_id}?title=${tournament_name}&creator=${creator_id}`
    );
  };
  
  const getTournaments = async () => {
    const { ok, data } = await customAxios.get("api/v1/tournament");
    if (ok) {
      setTournaments(data.tournaments);
      data.joined_tournaments && setJoinedTournaments(data.joined_tournaments);
    }
  };

  const onJoin = async (tournament_id, tournament_name, creator_id) => {
    play()
    setIsJoining(true);
    await customAxios.post(
      `api/v1/tournament/${tournament_id}/player`
    );
    setIsJoining(false);
    gotoReadyScreen(tournament_id, tournament_name, creator_id);
  };

  
  useEffect(() => {
    socket.on("TOURNAMENT_CREATE", (data) => {
      let tmpTournaments = [...tournaments];
      tmpTournaments.unshift(data);
      setTournaments(tmpTournaments);
    });
    return () => {
      socket.off("TOURNAMENT_CREATE");
    };
  }, [])

  useEffect(() => {
    getTournaments();
  }, [history.location.pathname]);

  return (
    <styleModule.JoinContainer>
      <div className="join-wrapper">
        <div className="new-tournament">
          <div className="new-tournament-values">
            <InputNumber
              min={1}
              max={1000}
              className="new-tournament-rounds"
              defaultValue={totalRounds}
              onChange={onChangeMaxRounds}
            />
            <Input
              className="new-tournament-name"
              placeholder="new tournament name"
              value={newTournamet}
              onChange={(e) => setNewTournament(e.target.value)}
            />
          </div>
          <Button
            className="btn-create-game"
            onClick={onCreateGame}
            loading={isCreating}
          >
            Create New Game
          </Button>
        </div>
        <SingleText size={32} weight="bold" marginBottom={37}>
          Dice Bingo
        </SingleText>
        <div className="game-options">
          <Row justify="center" align="middle">
            <Col span={10}>
              <h4 className="join-label title">Tournaments</h4>
            </Col>
            <Col span={7}>
              <h4 className="join-label title">Creator</h4>
            </Col>
            <Col span={7}></Col>
          </Row>
          {tournaments.length > 0 ? (
            tournaments.map((_tournament, _i) => (
              <Row key={_i} justify="center" align="middle">
                <Col span={10}>
                  <h4 className="join-label">{_tournament.name}</h4>
                </Col>
                <Col span={7}>
                  <h4 className="join-label">{_tournament.creator.name}</h4>
                  <p className="join-label">
                    {moment(_tournament.created_at).format("hh:mm:ss")}
                  </p>
                </Col>
                <Col span={7}>
                  {_tournament.status === 2 && (
                    <p className="join-label">Started</p>
                  )}
                  {_tournament.status === 3 && (
                    <p className="join-label">Completed</p>
                  )}
                  {_tournament.status === 0 &&
                    // (_tournament.creator.user_id === authUser.id ? (
                    (joinedTournaments.includes(_tournament.id) ? (
                      <Button
                        onClick={() =>
                          gotoReadyScreen(
                            _tournament.id,
                            _tournament.name,
                            _tournament.creator.user_id
                          )
                        }
                      >
                        Back to game
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          onJoin(
                            _tournament.id,
                            _tournament.name,
                            _tournament.creator.user_id
                          )
                        }
                        loading={isJoining}
                      >
                        Join Now
                      </Button>
                    ))}
                </Col>
              </Row>
            ))
          ) : (
            <div className="empty-tournaments">
              <SingleText>No Tournament</SingleText>
            </div>
          )}
        </div>
      </div>
    </styleModule.JoinContainer>
  );
}

export default Theme(Join, 'containers/MultiPlayer/Join');