/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect } from "react";
import { socket } from "service/socket";
import { getJwt } from "service/jwt";
import { DataContext } from "contexts/DataContextContainer";
import { useHistory, useParams } from "react-router-dom";
import SingleText from "components/SingleText";
import Countdown from "containers/MultiPlayer/Countdown";
import { BG } from "assets/video";
import { ThemeContext } from 'contexts/ThemeContext';
import Theme from 'containers/Theme';
import { Themes } from "contexts/ThemeContext";

function LayoutMulti({ children, styleModule, themeSounds }) {
  const history = useHistory();
  const token = getJwt();
  const { round, tournament } = useParams();
  const {
    startedRound,
    setStartedRound,
    setEndRound,
    maxRounds,
    setCompletedRound,
    streakStrikes
  } = useContext(DataContext);
  const theme = useContext(ThemeContext);

  const onCompleteCountDown = async (completed) => {
    setStartedRound(false);
    setEndRound(false);
    if (completed) {
      history.push(
        `/tournaments/${tournament}/${round ? parseInt(round) + 1 : 1}`
      );
    }
  };

  socket.on("connect", function () {
    if (token) {
      socket.emit("AUTHORIZE", { token: token }, (response) => {
        console.log("AUTHORIZED", response.status); // ok
      });
    }
  });
  socket.on("disconnect", function () {
    console.error("disconnected socket");
  });

  const handleTournamentRoundStart = () => {
    console.info('TOURNAMENT_ROUND_START');
    let currentRound = round ? parseInt(round) : 1;
    if (currentRound < maxRounds) {
      let delay = 2000;

      if (streakStrikes >= 3) {
        delay = 8000;
      }

      if (history.location.pathname.indexOf('ready') !== -1) {
        delay = 0;
      }

      setTimeout(() => {
        setCompletedRound(false);
        setStartedRound(true);
      }, delay);
    }
  }

  useEffect(() => {
    socket.on("TOURNAMENT_ROUND_START", handleTournamentRoundStart);
    return () => {
      socket.off("TOURNAMENT_ROUND_START");
    };
  }, [streakStrikes]);

  return (
      <styleModule.LayoutMultiContainer className="sample-multi">
        {(theme === Themes.DICE) ?
        <video autoPlay muted loop id="bgVideo">
          <source src={BG} type="video/mp4"></source>
        </video>
        : null }
        {startedRound && (
          <div className="countdown-wrapper">
            <SingleText weight="bold" size={24} marginBottom={10}>
              Round {round ? parseInt(round) + 1 : 1} Starts In
            </SingleText>
            <Countdown onCompleteCountDown={onCompleteCountDown} />
          </div>
        )}
        {children}
      </styleModule.LayoutMultiContainer>
  );
}

export default Theme(LayoutMulti, 'layouts/LayoutMulti');