/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";
import SingleText from "components/SingleText";
import React, { useEffect, useState } from "react";
import { RemoveTournamentContainer } from "./style";
import moment from "moment";
import { useHistory } from "react-router-dom";
import customAxios from "service/customAxios";
import { socket } from "service/socket";

export default function RemoveTournament() {
  const history = useHistory();
  const [tournaments, setTournaments] = useState([]);

  const getTournaments = async () => {
    const { ok, data } = await customAxios.get("api/v1/tournament");
    if (ok) {
      setTournaments(data.tournaments);
    }
  };

  const onDeleteTournament = async(tournament_id) => {
    const { ok } = await customAxios.delete(`api/v1/tournament/${tournament_id}`);
    if (ok) {
      setTournaments(tournaments.filter(_tournament => _tournament.id !== tournament_id))
    }
  }

  socket.on("TOURNAMENT_CREATE", (data) => {
    let tmpTournaments = [...tournaments];
    tmpTournaments.unshift(data);
    setTournaments(tmpTournaments);
  });

  useEffect(() => {
    getTournaments();
  }, [history.location.pathname]);

  return (
    <RemoveTournamentContainer>
      <div className="remove-tournaments-wrapper">
        <div className="game-options">
          <Row justify="center" align="middle">
            <Col span={6}>
              <h4 className="remove-tournaments-label title">Tournaments</h4>
            </Col>
            <Col span={7}>
              <h4 className="remove-tournaments-label title">Creator</h4>
            </Col>
            <Col span={6}>
              <h4 className="remove-tournaments-label title">Status</h4>
            </Col>
            <Col span={5}></Col>
          </Row>
          {tournaments.length > 0 ? (
            tournaments.map((_tournament, _i) => (
              <Row key={_i} justify="center" align="middle">
                <Col span={6}>
                  <h4 className="remove-tournaments-label">
                    {_tournament.name}
                  </h4>
                </Col>
                <Col span={7}>
                  <h4 className="remove-tournaments-label">
                    {_tournament.creator.name}
                  </h4>
                  <p className="remove-tournaments-label">
                    {moment(_tournament.created_at).format("hh:mm:ss")}
                  </p>
                </Col>
                <Col span={6}>
                  {_tournament.status === 2 && (
                    <p className="remove-tournaments-label">Started</p>
                  )}
                  {_tournament.status === 3 && (
                    <p className="remove-tournaments-label">Completed</p>
                  )}
                  {_tournament.status === 0 && (
                    <p className="remove-tournaments-label">Pending</p>
                  )}
                </Col>
                <Col span={5}>
                  <Button onClick={() => onDeleteTournament(_tournament.id)}>Delete</Button>
                </Col>
              </Row>
            ))
          ) : (
            <div className="empty-tournaments">
              <SingleText>No Tournament</SingleText>
            </div>
          )}
        </div>
      </div>
    </RemoveTournamentContainer>
  );
}
