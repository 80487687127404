import * as diceController from 'service/theme/dice/controller';

export const ensureDefaultFunction = (funcs) => {
	const diceControllerFunctions = Object.keys(diceController);

	diceControllerFunctions.forEach((name) => {
		if (funcs[name] === undefined) {
			funcs[name] = diceController[name];
		}
	});

	return funcs;
};