/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import { keyframes } from "@emotion/core";
import Reveal from "react-awesome-reveal";
import { numberGroup, statusColors, scoreOptions } from "service";
import { DataContext } from "contexts/DataContextContainer";
import SingleAntImage from "components/SingleAntImage";
import { IconCheckNum, StarStreak } from "assets/images";
import { ThemeContext, Themes } from "contexts/ThemeContext";
import ApngComponent from "react-apng";
import { ParticleClick } from "assets/images/battingpractice";
import Theme from 'containers/Theme';

let soundPlayed = false;

const animations = {
  1: "swing",
  2: "heartBeat",
  3: "jello",
  4: "tada",
};

const riseAnimation = keyframes`
  0% { 
    transform: translateY(0px);
  }
  1% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% { 
    transform: translateY(-70px); 
    opacity: 0;
  }
`;

const Number = ({
  order,
  status,
  isFreePick = false,
  countRemained,
  newWinGroupKeys = [],
  rolledNum,
  onClick,
  styleModule,
  globalThemeData,
  themeSounds,
  previousStreakStrike
}) => {
  const { 
    skipping,
    additionalScore,
    isPlayingSound
  } = useContext(DataContext);
  const [curStatus, setCurStatus] = useState(statusColors.white);
  const [isPlaying, setIsPlaying] = useState(false);
  const theme = useContext(ThemeContext);
  const dust = useRef();

  const [ playSoundOrangeTile ] = themeSounds.useSoundOrangeTile();

  let classDust = 'dust' + (isPlaying ? ' dust--show' : ' dust--hide');

  useEffect(() => {
    if(curStatus === status) return;
    soundPlayed = false;
    let delay = 0;
    if(status == statusColors.orange && theme !== Themes.DICE){
      delay = previousStreakStrike < 2 ? 1000 : 4000;
    }
    const t = setTimeout(() => {
      if(curStatus !== status){
        setCurStatus(status);
      }
    }, delay);
    return () => clearTimeout(t);
  }, [status]);

  useEffect(() => {
    if(curStatus === statusColors.orange && isPlayingSound && !soundPlayed) {
      playSoundOrangeTile();
      soundPlayed = true;
    }
  }, [curStatus]);

  if(additionalScore?.order === order && additionalScore?.addScore !== scoreOptions.checkMark){
    if(!isPlaying) {
      if(dust.current){
        dust.current.one();
      }else{
        classDust = 'dust dust--hide';
      }
      setIsPlaying(true);
    }
  }else if(isPlaying) {
    setIsPlaying(false);
  }

  return (
    <styleModule.NumberContainer
      className={`number ${order === 13 && "number7"} ${
        curStatus == statusColors.orange
          ? `animate__animated animate__${
              animations[countRemained.toString()]
            } animate__infinite animate__slow`
          : ""
      } ${
        newWinGroupKeys.length > 0 &&
        newWinGroupKeys.find((_key) =>
          numberGroup[_key].includes(order.toString())
        )
          ? "animate__animated animate__flash animate__repeat-1"
          : ""
      }`}
      status={parseInt(curStatus)}
      onClick={() => {
        if (status == statusColors.orange) {
          onClick();
        }
      }}
    >
      <styleModule.SingleText status={curStatus} order={order}>
        {globalThemeData.numbersTemp[order - 1]}
      </styleModule.SingleText>

      {rolledNum === globalThemeData.numbersTemp[order - 1] && skipping && (
        <SingleAntImage
          className="icon-check-num"
          src={IconCheckNum}
          width="100%"
          height="auto"
        />
      )}
      {isFreePick && curStatus == statusColors.white && (
        <SingleAntImage
          className="icon-free-num"
          src={StarStreak}
          width="100%"
          height="auto"
          onClick={onClick}
        />
      )}

      {theme === Themes.BATTINGPRACTICE ? <ApngComponent ref={dust} className={classDust} src={ParticleClick} rate={1} /> : null}
      {additionalScore?.order === order && (
        <Reveal
          className="add-score-value"
          duration={3000}
          delay={theme !== Themes.DICE && additionalScore.addScore !== scoreOptions.checkMark ? 0 : previousStreakStrike < 2 ? 1000 : 4000}
          keyframes={riseAnimation}
        >
          <styleModule.SingleTextOther addScore={additionalScore.addScore}>
            +{additionalScore.addScore}
          </styleModule.SingleTextOther>
        </Reveal>
      )}
    </styleModule.NumberContainer>
  );
};

export default React.memo(Theme(Number, 'components/Number'));
