/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeContext } from 'contexts/ThemeContext';
import { useContext, useEffect } from 'react';
import { ensureDefaultFunction } from './theme/controller-helper';

export const useThemeStyles = (setStyleModule, pwd) => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    const fetchStyleModule = async () => {
      import(`../${pwd}/theme/${theme}/style`).then((styleModule) => {
        setStyleModule(styleModule);
      });
    }
    fetchStyleModule();
  }, []);
}

export const useGlobalThemeData = (setGlobalThemeData) => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    const fetchGlobalThemeData = async () => {
      import(`../service/theme/${theme}/data`).then((globalThemeData) => {
        setGlobalThemeData(globalThemeData);
      });
    }
    fetchGlobalThemeData();
  }, []);
}

export const useThemeSounds = async (setThemeSounds, pwd) => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    const fetchThemeSounds = async () => {
      import(`../${pwd}/theme/${theme}/sounds`).then((themeSounds) => {
        setThemeSounds(themeSounds);
      });
    }
    fetchThemeSounds();
  }, []);
}

export const useThemController = async (setController) => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    const fetchThemeSounds = async () => {
      import(`service/theme/${theme}/controller`).then((controller) => {
        setController(ensureDefaultFunction(controller));
      });
    }
    fetchThemeSounds();
  }, []);
}