import React, { useEffect, useState, useContext } from "react";
import { ThemeContext, Themes } from 'contexts/ThemeContext';
import { Space } from "antd";
import { Hole } from "components/Hole";
import { Transition } from 'react-transition-group';
import Theme from 'containers/Theme';
import { DataContext } from "contexts/DataContextContainer";

const duration = 1000;

const transitionStyles = {
  entering: {},
  entered:  {},
  exiting:  {
    transition: `opacity ${duration}ms ease-in-out, margin-top ${duration}ms ease-in-out`,
    marginTop: '30px',
    opacity: 0,
  },
  exited:  {
    transition: `opacity ${duration}ms ease-in-out, margin-top ${duration}ms ease-in-out`,
    marginTop: '30px',
    opacity: 0,
  },
};

const Labels = {
  'dice': {
    anySeven: 'ANY SEVEN',
    gameOver: 'ANY SEVEN',
    points100_100: '',
    points100_points: '',
  },
  'battingpractice': {
    anySeven: 'TRIPLE RED BONUS',
    gameOver: 'TRIPLE RED BONUS',
    points100_100: '100',
    points100_points: 'POINTS',
  }
};

const pointsCoordinates = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

window.addEventListener('resize', (event) => {
  const elem = document.querySelector('.points-placeholder');

  if (elem) {
    const rect = elem.getBoundingClientRect()

    pointsCoordinates.bottom = rect.bottom;
    pointsCoordinates.right = rect.right;
  }
});

const Strikes = (props) => {
  const theme = useContext(ThemeContext);
  const {
    endRound,
    streakStrikes,
    styleModule,
    themeSounds,
    watchMode
  } = props;
  const [internalStrikes, setInternalStrikes] = useState(0);
  const {
    show100Points,
    setShow100Points,
    isPlayingSound
  } = useContext(DataContext);
  const [ playSoundCrowdCheer ] = themeSounds.useSoundCrowdCheer();
  const [centerPosition, setCenterPosition] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    if (internalStrikes >= 3 && !watchMode) {
      setShow100Points(true);
      isPlayingSound && playSoundCrowdCheer();
    }
  }, [internalStrikes, isPlayingSound, playSoundCrowdCheer, setShow100Points, watchMode]);

  useEffect(() => {
    if (show100Points) {
      const pointsTimeOut = setTimeout(() => {
        setShow100Points(false);
      }, 5000);
      return () => clearTimeout(pointsTimeOut);
    }
  }, [show100Points, setShow100Points]);

  const updateTargetPositions = () => {
    const elem = document.querySelector('body');

    const position = elem.getBoundingClientRect();
    const x = pointsCoordinates.bottom - (position.bottom / 2);
    const y = pointsCoordinates.right - (position.right / 2);

    const offsetTop = -100;
    const offsetLeft = -100;

    setCenterPosition({
      top: (x * -1) + offsetTop,
      left: (y * -1) + offsetLeft,
    });
  }

  useEffect(() => {
    updateTargetPositions();
    let delay = 0;
    if(theme !== Themes.DICE){
      delay = internalStrikes >= 2 ? 4000 : 1000;
    }
    setTimeout(() => {
      setInternalStrikes(streakStrikes);
    }, delay);
  }, [streakStrikes, internalStrikes, theme]);

  useEffect(() => {
    const elem = document.querySelector('.points-placeholder');

    if (elem) {
      const rect = elem.getBoundingClientRect()

      pointsCoordinates.bottom = rect.bottom;
      pointsCoordinates.right = rect.right;
    }
  });

  let holes = [];

  if (theme === Themes.DICE) {
    holes = [
      <styleModule.HolesContainer className="holes-container" animate={endRound}>
        <div className={`holes`}>
          <span className="holes-text">
            {endRound ? "GAME OVER" : "ANY SEVEN"}
          </span>
          <Space direction="horizontal" size={5}>
            {Array.from({ length: 3 }, (v, i) => i + 1).map((_hole, _i) => (
              <Hole key={_i} hole={_hole} holeTimes={streakStrikes} />
            ))}
          </Space>
        </div>
      </styleModule.HolesContainer>
    ];
  } else {
    let balls = [];
    balls = Array.from({ length: 3 }, (v, i) => i + 1).map((_hole, _i) => (
      <Transition enter={false} in={_hole <= internalStrikes} timeout={1000}>
        {state => (
          <styleModule.RedBall
            style={transitionStyles[state]}
            className="red-ball"
            key={`red-spin-ball-${_i}`}
            spinning={internalStrikes === 2}
          />
        )}
      </Transition>
    ));

    holes = [
      <styleModule.BallsWrapper key="balls-wrapper">
        {balls}
      </styleModule.BallsWrapper>
    ];
  }

  return <styleModule.HolesContainer className="holes-container" animate={endRound}>
    <div className="holes">
      <div className="holes-text">
        {endRound ? Labels[theme].gameOver : Labels[theme].anySeven}
      </div>
      <Space direction="horizontal" size={5}>
        {holes}
      </Space>
    </div>
    <div className="points-placeholder" />
    {styleModule.Points100Container ? (
      <styleModule.Points100Container
        top={centerPosition.top}
        left={centerPosition.left}
        fly={show100Points}
      >
        {Labels[theme].points100_100}
        {!show100Points ?
          (
            <>
              {' '}
              {Labels[theme].points100_points}
            </>
          )
          : null
        }
      </styleModule.Points100Container>
    ) : null}
    {styleModule.Overlay && show100Points ? <styleModule.Overlay /> : null}
  </styleModule.HolesContainer>
};

export default Theme(Strikes, 'components/Strikes');