import React, { useEffect, useRef, useState, useContext } from "react";
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';

const RoundEndWinner = (props) => {
  const { styleModule, themeSounds, rank, prize, hooks } = props;
  const {
    isPlayingSound,
    moneyRank
  } = useContext(DataContext);
  const requestRef = useRef();
  const winner = useRef(rank <= moneyRank);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(true);

  if(isPlayingSound){
    if(winner.current)
      themeSounds.useSoundFirstPlace();
    else
      themeSounds.useSoundRoundEnd();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      animateCount(0, prize, 2000);
    }, 1000);
    return() => {
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if(!show){
      const timer = setTimeout(() => {
        hooks.setShowWinningStatus(false);
        hooks.setShowNextModal(true);
      }, 2000);
      return() => {
        clearTimeout(timer);
      }
    }
  }, [show, hooks]);

  function animateCount(start, end, dur){
    let startTime = null;
    const step = (time) => {
      if(!startTime) startTime = time;
      const progress = Math.min((time - startTime) / dur, 1);
      setCount(Math.floor(progress * (end - start) + start));
      if(progress < 1){
        requestRef.current = requestAnimationFrame(step);
      }else{
        setTimeout(() => setShow(false), 5000);
      }
    }
    requestRef.current = requestAnimationFrame(step);
  }

  function getSuffix(){
    let ones = rank % 10;
    let tens = rank % 100;
    if(ones === 1 && tens !== 11)
      return 'ST';
    else if(ones === 2 && tens !== 12)
      return 'ND';
    else if(ones === 3 && tens !== 13)
      return 'RD';
    else
      return 'TH';
  }

  const modifier = () => show ? 'in' : 'out';

  if(winner.current){
    return(
      <styleModule.StyleContainer>
        <div className={"ray ray--" + modifier()}></div>
        <div className={"prize prize--winner prize--" + modifier()}><span>{'$' + count}</span></div>
        <div className={"trophy trophy--" + modifier()}>
          <span className="number">{rank}</span>
          <span className="word">{getSuffix()}</span>
        </div>
      </styleModule.StyleContainer>
    );
  }else{
    return(
      <styleModule.StyleContainer>
        <div className={"prize prize--retry prize--" + modifier()}><span>TRY AGAIN</span></div>
      </styleModule.StyleContainer>
    );
  }
};

export default Theme(RoundEndWinner, 'components/RoundEndWinner');