import styled from "@emotion/styled";
import { sizes } from "service/theme/dice";

export const RemoveTournamentContainer = styled.div`
  align-self: start;
  position: relative;
  width: ${sizes.mainWidth}px;
  max-width: 500px;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .remove-tournaments-wrapper {
    width: 100%;
    height: 100%;
    flex: 1;
    background: linear-gradient(45deg, black, transparent);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    min-height: calc(100vh);
    .new-tournament {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      .new-tournament-name {
      }
      .btn-create-game {
      }
    }
    .game-options {
      width: 100%;
      .ant-row {
        margin-bottom: 20px;
        .remove-tournaments-label {
          font-size: 12px;
          color: white;
          font-weight: 500;
          text-align: center;
          margin-bottom: 0;
          &.title {
            font-size: 16px;
          }
        }
      }
      .empty-tournaments {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
