import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import Theme from 'containers/Theme';

function Lobby(props) {
  const {
    styleModule
  } = props;
  const [time, setTime] = useState(0);
  const timer = useRef(null);
  const data = useRef([]);

  useEffect(() => {
    data.current.push({title: 'Dollar Micro', startTime: 0});
    data.current.push({title: '$10,000 Satellite', startTime: 0});
    data.current.push({title: '$1 Million Jackpot', startTime: 0});
    data.current.push({title: '$20 High Roller', startTime: 0});

    timer.current = setInterval(() => {
      const now = Date.now();
      data.current.forEach((value) => {
        if(value.startTime <= now){
          value.startTime = now + (60 + Math.floor(Math.random() * 180)) * 1000;
        }
      });
      setTime(now);
    }, 1000);
    
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const handleJoin = () => {
    console.log('join');
  };

  const handleInfo = () => {
    console.log('info');
  };

  const getTimeLeft = (startTime) => {
    const timeLeft = startTime - Date.now();
    const ms = 60000;
    if(timeLeft > ms){
      return(
        <div className="counter-box counter-box--min">
          <h5>Next Game</h5>
          <div className="counter">
            <div>{Math.floor(timeLeft / ms)}</div>
            <div>Approx.<br/>Mins<br/>Left</div>
          </div>
        </div>
      );
    }else{
      return(
        <div className="counter-box counter-box--sec">
          <h5>Next Game</h5>
          <div className="counter">
            <div>{Math.floor(timeLeft / 1000) + 's'}</div>
          </div>
        </div>
      );
    }
  };

  return(
    <styleModule.StyleContainer>
      <h1>Rolling Tournaments</h1>
      <h2>Continuous 24/7 action with multiple jackpots! Players who get lucky and choose wisely
share the prize pools.</h2>
      <div className="tournaments">
        {data.current.map((value, i) => (
          <div key={i} className="box">
            <div className="title">{value.title}<div className="info btn pad" onClick={handleInfo}></div></div>
            <Button className="btn btn--green" onClick={handleJoin}>JOIN</Button>
            <div className="details">
              <div className="jackpot-box">
                <h3>JACKPOTS!</h3>
                <hr/>
                <div className="prize">$100,000.00</div>
                <h4>MAJOR</h4>
                <hr/>
                <div className="prize">$1,234.56</div>
                <h4>MINOR</h4>
              </div>
              <div className="info-box">
                <table>
                  <tbody>
                    <tr>
                      <td>Buy-In:</td>
                      <td><span className="yellow"><b>$0.85 + $0.05 + $0.10</b></span></td>
                    </tr>
                    <tr>
                      <td>Players:</td>
                      <td><b>1,234</b></td>
                    </tr>
                    <tr>
                      <td>Prize Pool:&nbsp;</td>
                      <td><b>$1,048.90</b></td>
                    </tr>
                  </tbody>
                </table>
                {getTimeLeft(value.startTime)}
                {value.startTime - Date.now() <= 30000 && <div className="last-chance"></div>}
              </div>
            </div>
            <div className="btn left-arrow">
              <div className="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12">
                  <path d="M7.4,10.6L2.8,6l4.6-4.6L6,0L0,6l6,6L7.4,10.6z"></path>
                </svg>
              </div>
            </div>
            <div className="btn right-arrow">
              <div className="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12">
                  <path d="M7.4,10.6L2.8,6l4.6-4.6L6,0L0,6l6,6L7.4,10.6z"></path>
                </svg>
              </div>
            </div>
            <div className="pagination">
              <div></div>
              <div></div>
              <div className="pagination__active"></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ))}
      </div>
    </styleModule.StyleContainer>
  );
}

export default Theme(Lobby, 'containers/MultiPlayer/Lobby');