import React, {useRef, useContext, useEffect } from 'react';
import { DataContext } from 'contexts/DataContextContainer';
import ApngComponent from 'react-apng';
import BallThrow from 'components/BallThrow';
import { LauncherReload } from 'assets/images/battingpractice';
import Theme from 'containers/Theme';

function BallLauncher(props){
  const { styleModule, themeSounds, reload, number, streakStrikes, endRound, skipping, watchMode } = props;

  const { isPlayingSound } = useContext(DataContext);
  const isReloaded = useRef(false);
  const launcher = useRef();

  const [ playSoundCannonShot ] = themeSounds.useSoundCannonShot();

  useEffect(() => {
    let launchPlayer = null;

    const t = setTimeout(() => {
      launchPlayer = launcher?.current?.player;
      if(launchPlayer && reload !== isReloaded.current){
        if(reload){
          launchPlayer.on('frame', frameHandler);
          launcher.current.one();  
        } else {
          launchPlayer.renderNextFrame();
          isPlayingSound && !watchMode && playSoundCannonShot();
        }
        isReloaded.current = reload;
      }
    }, 100);
    return() => {
      clearTimeout(t);
    }
  });

  function frameHandler(e){
    if(e >= 28){
      launcher.current.pause();
      launcher.current.player.off('frame', frameHandler);
    }
  }

  return(
    <styleModule.StyleContainer>
      <BallThrow number={number} throwBall={!reload} streakStrikes={streakStrikes} endRound={endRound} skipping={skipping} watchMode={watchMode} />
      <ApngComponent ref={launcher} className='launcher' src={LauncherReload} rate={1} />
    </styleModule.StyleContainer>
  );
}

export default Theme(BallLauncher, 'components/BallLauncher');