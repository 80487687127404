export { default as Background } from './background.png';
export { default as BtnStart } from './btn-start@2x.png';
export { default as BgHole } from './bgHole.png';
export { default as BgHolesContainer } from './holes.png';
export { default as Pane } from './ImgPaneContainer.png';
export { default as NumTile } from './number-tile.png';
export { default as TileRedImg } from './tile-umpire@2x.png';
export { default as TileRed } from './TileRed.png';
export { default as NowWatch } from './now_watch.png';
export { default as NextRoundPoints } from './next_round_points.png';
export { default as TripleRedBonusContainer } from './triple_red_bonus_container.png';
export { default as RedBallsSmall } from './red_balls_small.png';
export { default as WhiteBallsSmall } from './balls_white_small.png';
export { default as Umpire } from './ampire_strikeback.png';
export { default as LauncherReload } from './Launcher_animated.png';

export { default as LeaderBoard } from './leaderboard/scoreboard-structure@2x.png';
export { default as LeaderBoardTop } from './leaderboard/leaderboard-container@2x.png';
export { default as LeaderBoardMid } from './leaderboard/leaderboard-mid.png';
export { default as MenuButton } from './leaderboard/btn-menu@2x.png';

export { default as SpectatorBg } from './spectator.png';

export { default as StreakBlue } from './streak-blue.png';
export { default as StreakGreen } from './streak-green.png';
export { default as StreakSlots } from './streak-slots.png';
export { default as StreakContainer } from './streak-container.png';
export { default as OutsContainer } from './outs-container.png';
export { default as OutsIndicator } from './outs-indicator.png';
export { default as OutsSlots } from './outs-slots.png';
export { default as BallIn } from './BallIntro.png';
export { default as BallOut } from './BallOutro.png';
export { default as RedBallFly } from './flying_red.png';
export { default as WhiteBallFly } from './whiteball_animated.png';
export { default as RedWhiteBallFly } from './flying_redwhite_ball.png';
export { default as WhiteBall } from './white_ball.png';
export { default as RedBall } from './red_ball.png';
export { default as ParticleHit } from './particle_hit.png';
export { default as ParticleClick } from './Particle_tile_click.png';
export { default as GameOver } from './game_over.png';
export { default as AutoPickOff } from './btn_autopick_off.png';
export { default as AutoPickOn } from './btn_autopick_on.png';
export { default as FoulBall } from './foul-ball-text.png';
export { default as PrizeContainer } from './counter_container.png';
export { default as ModalContainer } from './container@2x.png';
export { default as Ray } from './ray.png';
export { default as Trophy } from './trophy.png';