/* eslint-disable eqeqeq */


export const statusColors = {
  green: 1,
  orange: 3,
  white: 0,
  blue: 2,
};

export const numsTemp = {
  1: { id: "1", value: 2, status: statusColors.white },
  2: { id: "2", value: 9, status: statusColors.white },
  3: { id: "3", value: 8, status: statusColors.white },
  4: { id: "4", value: 6, status: statusColors.white },
  5: { id: "5", value: 3, status: statusColors.white },
  6: { id: "6", value: 6, status: statusColors.white },
  7: { id: "7", value: 4, status: statusColors.white },
  8: { id: "8", value: 12, status: statusColors.white },
  9: { id: "9", value: 10, status: statusColors.white },
  10: { id: "10", value: 8, status: statusColors.white },
  11: { id: "11", value: 11, status: statusColors.white },
  12: { id: "12", value: 5, status: statusColors.white },
  13: { id: "13", value: 7, status: statusColors.white },
  14: { id: "14", value: 2, status: statusColors.white },
  15: { id: "15", value: 5, status: statusColors.white },
  16: { id: "16", value: 5, status: statusColors.white },
  17: { id: "17", value: 12, status: statusColors.white },
  18: { id: "18", value: 10, status: statusColors.white },
  19: { id: "19", value: 8, status: statusColors.white },
  20: { id: "20", value: 9, status: statusColors.white },
  21: { id: "21", value: 8, status: statusColors.white },
  22: { id: "22", value: 6, status: statusColors.white },
  23: { id: "23", value: 3, status: statusColors.white },
  24: { id: "24", value: 4, status: statusColors.white },
  25: { id: "25", value: 11, status: statusColors.white },
};

export const getRandomBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomIn = (nums) => {
  const randomMonth = nums[Math.floor(Math.random() * nums.length)];
  return randomMonth;
};

export const getFirstOrange = (statuses) => {
  return statuses.findIndex((_status) => _status == statusColors.orange) + 1;
};

export const getFirstFreePick = (statuses) => {
  return statuses.findIndex((_status) => _status == statusColors.white) + 1;
};
export const numSet = [
  1, 11, 20, 20, 22, 22, 30, 30, 30, 33, 33, 33, 40, 40, 40, 40, 44, 44, 44, 44,
  50, 50, 50, 50, 55, 55, 55, 55, 55, 7, 7, 7, 7, 7, 7,
];

export const getRandomNumber = (nums) => {
  return nums[Math.floor(Math.random() * nums.length)];
};

export const numberGroup = {
  w1: ["1", "2", "3", "4", "5"],
  w2: ["6", "7", "8", "9", "10"],
  w3: ["11", "12", "13", "14", "15"],
  w4: ["16", "17", "18", "19", "20"],
  w5: ["21", "22", "23", "24", "25"],
  h1: ["1", "6", "11", "16", "21"],
  h2: ["2", "7", "12", "17", "22"],
  h3: ["3", "8", "13", "18", "23"],
  h4: ["4", "9", "14", "19", "24"],
  h5: ["5", "10", "15", "20", "25"],
  c1: ["1", "7", "13", "19", "25"],
  c2: ["5", "9", "13", "17", "21"],
};

export const pairs = {
  "1": ['w1', 'h1', 'c1'],
  "2": ['w1', 'h2'],
  "3": ['w1', 'h3'],
  "4": ['w1', 'h4'],
  "5": ['w1', 'h5', 'c2'],
  "6": ['w2', 'h1'],
  "7": ['w2', 'h2', 'c1'],
  "8": ['w2', 'h3'],
  "9": ['w2', 'h4', 'c2'],
  "10": ['w2', 'h5'],
  "11": ['w3', 'h1'],
  "12": ['w3', 'h2'],
  "13": ['w3', 'h3', 'c1', 'c2'],
  "14": ['w3', 'h4'],
  "15": ['w3', 'h5'],
  "16": ['w4', 'h1'],
  "17": ['w4', 'h2', 'c2'],
  "18": ['w4', 'h3'],
  "19": ['w4', 'h4', 'c1'],
  "20": ['w4', 'h5'],
  "21": ['w5', 'h1', 'c2'],
  "22": ['w5', 'h2'],
  "23": ['w5', 'h3'],
  "24": ['w5', 'h4'],
  "25": ['w5', 'h5', 'c1'],
}

export const credits = {
  0: 0,
  1: 0.5,
  2: 0,
  3: 1,
  4: 2,
  5: 2,
  6: 4,
  7: 4,
  8: 7,
  9: 12,
  10: 15,
  buyStrike: 7,
  strike1: 150,
  strike2: 30,
};

export const scoreOptions = {
  blueTile: 1,
  checkMark: 2,
  greenLine: 5,
  fullCard2: 30
}

export const soundSetting = {
  volume: 0.5,
};

export const isAutoPickToggle = (target) => {
  if (target && target.id === 'autopick') {
    return true;
  }

  if (target && target.tagName.toLowerCase() !== 'button') {
    const parent = target.parentNode;

    if (parent && parent.id === 'autopick') {
      return true;
    }
  }

  return false;
}
