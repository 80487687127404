import SingleText from "components/SingleText";
import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { RegisterContainer } from "./style";
import { Link, useHistory } from "react-router-dom";
import customAxios from "service/customAxios";

export default function Register() {

  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async(values) => {
    setSubmitting(true);
    const result = await customAxios.post('api/v1/auth/register', values)
    if (result.ok) {
      history.replace('/login')
    }
    setSubmitting(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <RegisterContainer>
      <div className="register-left">
        <SingleText size={30}>register</SingleText>
        <Form
          name="basic"
          style={{width: 400}}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input placeholder='name' />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }, { type: 'email', message: 'Invalide email!'}]}
          >
            <Input placeholder='email' />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Register
            </Button>
            Or <Link to="/login">login now!</Link>
          </Form.Item>
        </Form>
      </div>
      <div className="register-right">sdfsdf</div>
    </RegisterContainer>
  );
}
