import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from 'contexts/DataContextContainer';
import { ThemeContext, Themes } from 'contexts/ThemeContext';
import Theme from 'containers/Theme';

function StrikeAnim(props){
  const {
    styleModule,
    themeSounds,
    show
  } = props;
  const {
    isPlayingSound,
    streakStrikes
  } = useContext(DataContext);
  let theme = useContext(ThemeContext);

  const [isShown, setIsShown] = useState(false);
  const [ playSoundUmpireOut ] = themeSounds.useSoundUmpireOut();
  const [ playSoundBoo ] = themeSounds.useSoundBoo();

  useEffect(() => {
    let t = setTimeout(() => {
      if(show !== isShown){
        if(show){
          if(theme === Themes.DICE){
            window.initAnimationStrike();
          }
        }else{
          if(theme !== Themes.DICE && isPlayingSound){
            playSoundUmpireOut();
            streakStrikes < 3 && playSoundBoo();
          }
        }
        setIsShown(show);
      }
    }, 50);
    return() => {
      clearTimeout(t);
    }
  });

  return(
    <styleModule.StyleContainer delay={streakStrikes > 2 ? 4 : 1} id="animation_container" className={show ? "strike-in" : "strike-out"}>
      <canvas id="canvas"></canvas>
      <div id="dom_overlay_container"></div>
    </styleModule.StyleContainer>
  );
}

export default Theme(StrikeAnim, 'components/StrikeAnim');